import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * Component that renders a loading indicator inside a table row
 */
function LoadingTableRow({ colSpan = 9, message = "Carregando..." }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <Spinner animation="border" role="status" className="mt-2">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
        <p className="mb-2">{message}</p>
      </td>
    </tr>
  );
}

LoadingTableRow.propTypes = {
  colSpan: PropTypes.number,
  message: PropTypes.string
};

export default LoadingTableRow;