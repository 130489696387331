import React, { useState, useEffect } from "react";
import Table from "./Table";
import LoadingTableRow from "./LoadingTableRow";
import MessageTableRow from "./MessageTableRow";
import PropTypes from "prop-types";
import TableContent from "./TableContent";

/**
 * Component that displays the history of backup executions
 */
function HistoryList({ 
  historyData, 
  isLoading, 
  taskId,
  onEmailModal,
  onSetFileSize,
  onShowDeleteModal,
  onSetHistoryId,
  onSetFileName
}) {
  const [sortedHistory, setSortedHistory] = useState([]);

  useEffect(() => {
    if (historyData && historyData.length > 0) {
      setSortedHistory([...historyData]);
    } else {
      setSortedHistory([]);
    }
  }, [historyData]);

  const handleSort = (column, direction) => {
    if (!historyData || historyData.length === 0) return;
    
    const sorted = [...sortedHistory].sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];
      
      // Handle specific data types
      if (column === 'tamanho' || column === 'tempo_total') {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
      } else if (column === 'data_execucao') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      } else if (column === 'hora_execucao') {
        // Convert HH:MM:SS to sortable value
        const [hoursA, minutesA, secondsA] = (valueA || '00:00:00').split(':').map(Number);
        const [hoursB, minutesB, secondsB] = (valueB || '00:00:00').split(':').map(Number);
        valueA = (hoursA * 3600) + (minutesA * 60) + secondsA;
        valueB = (hoursB * 3600) + (minutesB * 60) + secondsB;
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }
      
      // Sort direction
      if (direction === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
    
    setSortedHistory(sorted);
  };

  // Render loading state
  if (isLoading) {
    return (
      <Table tipo="histBackups">
        <LoadingTableRow />
      </Table>
    );
  }

  // No task selected
  if (taskId == null) {
    return (
      <Table tipo="histBackups">
        <MessageTableRow message="Selecione Uma Tarefa" />
      </Table>
    );
  }
  
  // No history data available
  if (!historyData || historyData.length <= 0) {
    return (
      <Table tipo="histBackups">
        <MessageTableRow message="Nenhum Registro Encontrado" />
      </Table>
    );
  }

  // Render history data
  return (
    <Table 
      tipo="histBackups"
      onSort={handleSort}
    >
      {sortedHistory.map((item) => (
        <TableContent 
          key={item.id}
          dados={item} 
          index={item.id} 
          tipo="historico" 
          modalEmail={onEmailModal}
          setTamanhoArquivo={onSetFileSize}
          setShowModalArquivo={onShowDeleteModal}
          setIdHistorico={onSetHistoryId}
          setNomeDoArquivo={onSetFileName}
        />
      ))}
    </Table>
  );
}

HistoryList.propTypes = {
  historyData: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  taskId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onEmailModal: PropTypes.func.isRequired,
  onSetFileSize: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  onSetHistoryId: PropTypes.func.isRequired,
  onSetFileName: PropTypes.func.isRequired
};

export default HistoryList;