import { MD5 } from 'crypto-js';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormModal from './FormModal';


function ModalConfirmacao(props) {

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark modal-config-dark text-white' :
            'bg-light'
    )


    return (
        <div className={` ${props.show ? '' : 'diplay-none'}`}>
            <Modal show={props.show} onHide={() => { props.setShow(false); }} className='modal-confirmacao'>
                <Modal.Header closeButton className={backgroudClass}>
                    <Modal.Title>{props.titulo ? props.titulo : `Enviar Backup Por Email`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={backgroudClass}>

                    <p>{props.textoModal}</p>

                </Modal.Body>
                <Modal.Footer className={backgroudClass}>
                    <Button variant="primary" onClick={() => { props.setShow(false); }} className={props.tema === 'claro' ? 'text-black' : ''}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={props.funcao} className={props.tema === 'claro' ? 'text-black' : ''}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario
    }
}

export default connect(mapStateToProps, null)(ModalConfirmacao)