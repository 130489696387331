import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getBackupMensagem } from "../../redux/actions";
import MensagemModal from "../MensagemModal";


function MCTabelaHistorico(props) {
 
    const tableTemaEscuro = 'table-dark table-striped table-bordered table-hover' 
    const theadTemaEscuro = ''
    const tbodyTemaEscuro = ''

    const tableTemaClaro = 'table-light table-striped table-bordered table-hover'
    const theadTemaClaro = ''
    const tbodyTemaClaro = ''

    const trClases = `${props.tema === 'claro' ? 'hover:bg-neutral-200' : 'hover:bg-slate-700'} cursor-pointer`

    const dispatch = useDispatch()

    const iconeCorreto = <i className="bi bi-check-circle" style={{ color: props.tema === 'claro' ? 'green' : 'lime', fontSize: '1.2rem' }}></i>
    const iconeErro = <i className="bi bi-x-circle " style={{ color: props.tema === 'claro' ? 'red' : '#ff7777', fontSize: '1.2rem' }}></i>
    const iconeDownload = <i class="bi bi-file-arrow-down ml-1 text-green-500"></i>
    const iconeEmail = <i class="bi bi-envelope-check ml-1"></i>
    const iconeLixeira = <i class="bi bi-trash3 ml-1 text-red-500"></i>
    const iconeEngranagem = <i class="bi bi-pencil-square" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>
    const iconeDesativado = <i class="bi bi-slash-circle" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>
    const iconeAlerta = <i class="bi bi-exclamation-circle" style={{ fontSize: '1.2rem', color: props.tema === 'claro' ? '#ff9700' : 'yellow' }} ></i>
    const iconeRelogio = <i class="bi bi-stopwatch" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>
    const iconePlay = <i class="bi bi-play-circle-fill" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>

    function formatFileSize(size) {
        const units = ['KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)}${units[unitIndex]}`;
    }

    function formatarDataComHora(data) {
        const dataFormatada = new Date(data);
        const dia = dataFormatada.getDate().toString().padStart(2, '0');
        const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataFormatada.getFullYear().toString();
        const hora = dataFormatada.getHours().toString().padStart(2, '0');
        const minuto = dataFormatada.getMinutes().toString().padStart(2, '0');
        const segundo = dataFormatada.getSeconds().toString().padStart(2, '0');
        return `${dia}/${mes}/${ano}`;
    }

    function formatSeconds(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        if (hours > 0) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        } else if (minutes > 0) {
            return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        } else {
            return `00:${remainingSeconds.toString().padStart(2, '0')}`;
        }
    }

    function criaLinhaTabelas(data) {
        if (!props.tarefaSelecionada) {
            return (
                <tr>
                    <td colspan="8" >
                        <p className="">{"selecione uma tarefa"}</p>
                    </td>
                </tr>
            )
        } else if (data.length > 0) {
            return data.map((item, index) => (
                <tr key={index} className={trClases}>
                    <td onClick={() => dispatch(getBackupMensagem(item.id))}>{item.status === "C" ? iconeCorreto : iconeErro}</td>
                    <td onClick={() => dispatch(getBackupMensagem(item.id))}>{item.nome}</td>
                    <td onClick={() => dispatch(getBackupMensagem(item.id))}>{formatFileSize(item.tamanho)}</td>
                    <td onClick={() => dispatch(getBackupMensagem(item.id))}>{`${formatarDataComHora(item.execucao)} ${item.hora_execucao}`}</td>
                    <td onClick={() => dispatch(getBackupMensagem(item.id))}>{formatSeconds(item.tempo_total)}</td>
                    <td><span className="hover:underline">Baixar {iconeDownload}</span></td>
                    <td> <span className="hover:underline">Enviar {iconeEmail}</span></td>
                    <td> <span className="hover:underline">Apagar {iconeLixeira}</span></td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colspan="8" >
                        <p className="">{'Nenhuma infomação...'}</p>
                    </td>
                </tr>
            )
        }

    }


    return (
        <div>
            <MensagemModal />
            <p>Histórico</p>
            <div className={`cont-table-mc ${props.tema === 'claro' ? 'cont-table-white' : 'cont-table-black'}`}>
                <table className={`table ${props.tema === 'claro' ? tableTemaClaro : tableTemaEscuro} text-center table-sm`}>
                    <thead className={props.tema === 'claro' ? theadTemaClaro : theadTemaEscuro}>
                        <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Tamanho</th>
                            <th>Data Execução</th>
                            <th>Tempo</th>
                            <th colspan="3">Arquivo</th>
                        </tr>
                    </thead>
                    <tbody className={`${props.tema === 'claro' ? tbodyTemaClaro : tbodyTemaEscuro} text-sm`}>
                        {
                            props.carregandoTabelaHistoricoCliente ?
                                <tr>
                                    <td colspan="8" >
                                        <Spinner animation="border" role="status" className="mt-2">
                                            <span className="visually-hidden">Carregando...</span>
                                        </Spinner>
                                        <p className="mb-2">Carregando...</p>
                                    </td>
                                </tr> :
                                criaLinhaTabelas(props.dadosHistorico)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        dadosTarefas: state.appReducer.dadosTarefas,
        dadosHistorico: state.MCReducer.dadosHistorico,
        carregandoTabelaHistoricoCliente: state.MCReducer.carregandoTabelaHistoricoCliente,
    }
}

export default connect(mapStateToProps, null)(MCTabelaHistorico)