import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getClientes, getHistoricoCliente, getClienteTarefas } from "./actions";
import { Button } from "react-bootstrap";
import axios from "axios";
import apiUrl from "../../const";
import MCAdicionarClienteModal from "./MCAdicionarClienteModal";

function MCTabelaClientes(props) {
    // Atualizar ícones com melhor estilização
    const iconeCorreto = <i className="bi bi-check-circle-fill text-green-500 drop-shadow-md"></i>
    const iconeErro = <i className="bi bi-x-circle-fill text-red-500 drop-shadow-md"></i>
    const iconeAlerta = <i className="bi bi-exclamation-circle-fill text-yellow-500 drop-shadow-md"></i>
    const iconeRelogio = <i className="bi bi-stopwatch-fill text-blue-500 drop-shadow-md"></i>
    const iconeExpandir = <i className="bi bi-chevron-down transition-transform duration-300 transform group-hover:translate-y-0.5"></i>
    
    // Ícones para ordenação
    const iconeOrdAscendente = <i className="bi bi-caret-up-fill"></i>
    const iconeOrdDescendente = <i className="bi bi-caret-down-fill"></i>
    const iconeOrdNeutro = <i className="bi bi-arrow-down-up text-gray-400"></i>

    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [clienteAtual, setClienteAtual] = useState(null);
    const [showAdicionarModal, setShowAdicionarModal] = useState(false);
    
    // Estados para ordenação
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedClients, setSortedClients] = useState([]);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientes(props.dadosUsuario.id))
    }, []);
    
    useEffect(() => {
        if (props.dadosClientes && props.dadosClientes.length > 0) {
            setSortedClients([...props.dadosClientes]);
        } else {
            setSortedClients([]);
        }
    }, [props.dadosClientes]);

    function formatarIconeStatus(status, emExecucao) {
        if (emExecucao === 'SIM') {
            return iconeRelogio
        } else if (status === "E") {
            return iconeErro
        } else if (status === "C") {
            return iconeCorreto
        } else {
            return iconeRelogio
        }
    }

    // Nova função para obter a tarefa mais recente de um cliente
    function obterTarefaMaisRecente(cliente) {
        if (!cliente.tarefas || cliente.tarefas.length === 0) {
            return null;
        }
        
        // Ordena as tarefas pela data de execução (mais recente primeiro)
        return [...cliente.tarefas].sort((a, b) => {
            const dataA = new Date(a.execucao);
            const dataB = new Date(b.execucao);
            return dataB - dataA;
        })[0];
    }
    
    // Função para formatar a data
    function formatarData(dataString) {
        if (!dataString || dataString.includes('1899-11-30')) {
            return 'Não executado';
        }
        
        const data = new Date(dataString);
        
        // Format date to Brazilian format (DD/MM/YYYY)
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        
        // Format time - adjusting for local timezone
        const horas = data.getHours().toString().padStart(2, '0');
        const minutos = data.getMinutes().toString().padStart(2, '0');
        
        // return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
        return `${dia}/${mes}/${ano}`;
    }

    function handleClienteClick(item, index) {
        setClienteSelecionado(index);
        setClienteAtual(item);
        dispatch(getClienteTarefas(item.inscricao));
        setModalOpen(true);
        props.setTarefaSelecionada(true);
    }

    const handleAdicionarClick = () => {
        setShowAdicionarModal(true);
    };

    const handleCloseAdicionarModal = () => {
        setShowAdicionarModal(false);
        // Recarregar a lista de clientes após adicionar um novo
        dispatch(getClientes(props.dadosUsuario.id));
    };
    
    // Função para ordenar clientes
    const handleSort = (column) => {
        const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
        
        if (!props.dadosClientes || props.dadosClientes.length === 0) return;
        
        const sorted = [...sortedClients].sort((a, b) => {
            let valueA, valueB;
            
            // Tratamento especial para ordenação baseada na última tarefa
            if (column === 'ultima_tarefa') {
                const tarefaA = obterTarefaMaisRecente(a);
                const tarefaB = obterTarefaMaisRecente(b);
                valueA = tarefaA ? tarefaA.nome_tarefa : '';
                valueB = tarefaB ? tarefaB.nome_tarefa : '';
            } 
            // Ordenação baseada na data de execução da última tarefa
            else if (column === 'data_execucao') {
                const tarefaA = obterTarefaMaisRecente(a);
                const tarefaB = obterTarefaMaisRecente(b);
                valueA = tarefaA ? new Date(tarefaA.execucao).getTime() : 0;
                valueB = tarefaB ? new Date(tarefaB.execucao).getTime() : 0;
            }
            // Ordenação baseada no status
            else if (column === 'status') {
                const tarefaA = obterTarefaMaisRecente(a);
                const tarefaB = obterTarefaMaisRecente(b);
                valueA = tarefaA ? (tarefaA.status === 'C' ? 2 : tarefaA.status === 'E' ? 0 : 1) : -1;
                valueB = tarefaB ? (tarefaB.status === 'C' ? 2 : tarefaB.status === 'E' ? 0 : 1) : -1;
            }
            // Ordenação padrão para outros campos
            else {
                valueA = a[column] ? (typeof a[column] === 'string' ? a[column].toLowerCase() : a[column]) : '';
                valueB = b[column] ? (typeof b[column] === 'string' ? b[column].toLowerCase() : b[column]) : '';
            }
            
            // Ordenar
            if (newDirection === 'asc') {
                return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
            } else {
                return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
            }
        });
        
        setSortedClients(sorted);
    };
    
    // Renderiza o indicador de ordenação
    const renderSortIndicator = (column) => {
        if (sortColumn === column) {
            return sortDirection === 'asc' ? iconeOrdAscendente : iconeOrdDescendente;
        }
        return iconeOrdNeutro;
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <h2 className={`text-2xl font-bold ${props.tema === 'claro' ? 'text-neutral-800' : 'text-white'}`}>
                    <i className="bi bi-people-fill mr-2"></i>
                    Clientes
                </h2>
                <Button 
                    variant={props.tema === 'claro' ? "primary" : "outline-light"}
                    onClick={handleAdicionarClick}
                    className="flex items-center gap-2 shadow-md hover:shadow-lg transition-shadow"
                >
                    <i className="bi bi-person-plus-fill"></i>
                    Adicionar Cliente
                </Button>
            </div>
            
            {props.carregandoTabela ? (
                <div className={`flex justify-center items-center p-12 rounded-xl shadow-lg
                    ${props.tema === 'claro' ? 'bg-white' : 'bg-neutral-800'}`}>
                    <div className="flex flex-col items-center">
                        <div className="w-12 h-12 border-4 border-t-blue-500 border-b-blue-500 border-l-transparent border-r-transparent rounded-full animate-spin"></div>
                        <p className="mt-4 text-base font-medium text-neutral-500">Carregando dados dos clientes...</p>
                    </div>
                </div>
            ) : (
                <div className={`rounded-xl overflow-hidden shadow-lg border ${props.tema === 'claro' ? 'border-gray-200' : 'border-neutral-700'}`}>
                    <div className={`p-2 ${props.tema === 'claro' ? 'bg-blue-100' : 'bg-neutral-900/30'}`}>
                        <div className="flex justify-between items-center">
                            <h3 className={`font-semibold ${props.tema === 'claro' ? 'text-blue-800' : 'text-blue-200'}`}>
                                <i className="bi bi-table mr-2"></i>
                                Listagem de Clientes
                            </h3>
                            <div className={` ${props.tema === 'claro' ? 'text-blue-700' : 'text-blue-200'}`}>
                                {props.dadosClientes?.length || 0} clientes
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className={`min-w-full divide-y 
                            ${props.tema === 'claro' 
                                ? 'bg-white divide-gray-200' 
                                : 'bg-neutral-800 divide-neutral-700'}`}>
                            <thead className={props.tema === 'claro' ? 'bg-gray-50' : 'bg-neutral-700'}>
                                <tr>
                                    <th scope="col" 
                                        className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer
                                            ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-300'}`}
                                        onClick={() => handleSort('status')}
                                    >
                                        <div className="flex items-center">
                                            <span>Status</span>
                                            <span className="ml-1">{renderSortIndicator('status')}</span>
                                        </div>
                                    </th>
                                    <th scope="col" 
                                        className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer
                                            ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-300'}`}
                                        onClick={() => handleSort('nome')}
                                    >
                                        <div className="flex items-center">
                                            <span>Nome do Cliente</span>
                                            <span className="ml-1">{renderSortIndicator('nome')}</span>
                                        </div>
                                    </th>
                                    <th scope="col" 
                                        className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer
                                            ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-300'}`}
                                        onClick={() => handleSort('ultima_tarefa')}
                                    >
                                        <div className="flex items-center">
                                            <span>Última Tarefa</span>
                                            <span className="ml-1">{renderSortIndicator('ultima_tarefa')}</span>
                                        </div>
                                    </th>
                                    <th scope="col" 
                                        className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer
                                            ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-300'}`}
                                        onClick={() => handleSort('data_execucao')}
                                    >
                                        <div className="flex items-center">
                                            <span>Data de Execução</span>
                                            <span className="ml-1">{renderSortIndicator('data_execucao')}</span>
                                        </div>
                                    </th>
                                    <th scope="col" className={`px-6 py-3 text-center text-xs font-medium uppercase tracking-wider
                                        ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-300'}`}>
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={`divide-y ${props.tema === 'claro' ? 'divide-gray-200' : 'divide-neutral-700'}`}>
                                {sortedClients && sortedClients.length > 0 ? (
                                    sortedClients.map((item, index) => {
                                        const tarefaRecente = obterTarefaMaisRecente(item);
                                        const isEven = index % 2 === 0;
                                        return (
                                            <tr 
                                                key={index}
                                                className={`transition-all duration-200 cursor-pointer
                                                    ${props.tema === 'claro' 
                                                        ? isEven ? 'bg-white hover:bg-blue-50' : 'bg-gray-50 hover:bg-blue-50' 
                                                        : isEven ? 'bg-neutral-800 hover:bg-neutral-700' : 'bg-neutral-850 hover:bg-neutral-700'}`}
                                                onClick={() => handleClienteClick(item, index)}
                                            >
                                                <td className="px-6 py-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className={`flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center
                                                            ${tarefaRecente?.status === "C" ? 'bg-green-100' : 
                                                              tarefaRecente?.status === "E" ? 'bg-red-100' : 
                                                              tarefaRecente?.em_execucao === "SIM" ? 'bg-blue-100' : 'bg-gray-100'}`}>
                                                            <span className="text-xl">
                                                                {tarefaRecente ? formatarIconeStatus(tarefaRecente.status, tarefaRecente.em_execucao) : iconeRelogio}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`px-6 py-2 whitespace-nowrap ${props.tema === 'claro' ? 'text-gray-900' : 'text-white'}`}>
                                                    <div className="font-medium">{item.nome}</div>
                                                    <div className={`text-sm ${props.tema === 'claro' ? 'text-gray-500' : 'text-gray-400'}`}>
                                                        {item.inscricao}
                                                    </div>
                                                </td>
                                                <td className={`px-6 py-2 whitespace-nowrap`}>
                                                    <div className={`${props.tema === 'claro' ? 'text-gray-900' : 'text-white'}`}>
                                                        {tarefaRecente ? tarefaRecente.nome_tarefa : 'Nenhuma tarefa'}
                                                    </div>
                                                    {tarefaRecente && (
                                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                            ${tarefaRecente.status === "C" ? 
                                                              (props.tema === 'claro' ? 'bg-green-100 text-green-800' : 'bg-green-800 text-green-100') : 
                                                              tarefaRecente.status === "E" ? 
                                                              (props.tema === 'claro' ? 'bg-red-100 text-red-800' : 'bg-red-800 text-red-100') : 
                                                              (props.tema === 'claro' ? 'bg-blue-100 text-blue-800' : 'bg-blue-800 text-blue-100')}`}>
                                                            {tarefaRecente.status === "C" ? "Concluído" : 
                                                             tarefaRecente.status === "E" ? "Erro" : "Pendente"}
                                                        </span>
                                                    )}
                                                </td>
                                                <td className={`px-6 py-2 whitespace-nowrap ${props.tema === 'claro' ? 'text-gray-500' : 'text-gray-400'}`}>
                                                    {tarefaRecente ? (
                                                        <div className="flex items-center">
                                                            <i className="bi bi-clock mr-1"></i>
                                                            {formatarData(tarefaRecente.execucao)}
                                                        </div>
                                                    ) : 'N/A'}
                                                </td>
                                                <td className="px-6 py-2 whitespace-nowrap text-center">
                                                    <Button 
                                                        variant={props.tema === 'claro' ? "outline-primary" : "primary"}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            axios.post(`${apiUrl}/login`, {
                                                                emailInput: item.usuario,
                                                                senhaInput: "null",
                                                                senhaSemCripto: item.senha
                                                            }).then((response) => {
                                                                localStorage.setItem('token', response.data.token);
                                                                window.open('https://upcloud.net.br/painel/', '_blank');
                                                            }).catch((e) => {
                                                                alert('erro');
                                                            });
                                                        }}
                                                        className={`transition-all transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                                            ${props.tema === 'claro' ? 'hover:bg-blue-700 hover:text-white' : ''}`}
                                                        size="sm"
                                                    >
                                                        <i className="bi bi-box-arrow-up-right mr-1"></i>
                                                        Abrir Detalhes
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="5" className={`px-6 py-12 text-center
                                            ${props.tema === 'claro' ? 'text-gray-500' : 'text-neutral-400'}`}>
                                            <div className="flex flex-col items-center justify-center">
                                                <i className="bi bi-search text-4xl mb-3 opacity-50"></i>
                                                <p className="text-lg font-medium">Nenhum cliente encontrado</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            
            {/* Modal para adicionar novo cliente */}
            <MCAdicionarClienteModal 
                show={showAdicionarModal} 
                handleClose={handleCloseAdicionarModal}
                usuario_adm={props.dadosUsuario.id}
                tema={props.tema}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        dadosTarefas: state.appReducer.dadosTarefas,
        carregandoTabela: state.MCReducer.carregandoTabelaClientes,
        dadosClientes: state.MCReducer.dadosCliientes,
    }
}

export default connect(mapStateToProps, null)(MCTabelaClientes)