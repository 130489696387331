import React from "react";
import { connect, useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { downloadArquivo, getBackupMensagem, selecionaTarefa } from "../redux/actions";
import { abreTarefasConfig } from "../redux/actions";
import { obterDiaDaSemana } from "./utilitarios/obterDia";

/**
 * Component for table row content based on data type
 */
function TableContent(props) {
    const { index, dados, tipo } = props;
    const numeroLinhas = props.numeroLinhas;
    const dispatch = useDispatch();

    // Icons for various states
    const icons = {
        correct: <i className="bi bi-check-circle" style={{ color: props.tema === 'claro' ? 'green' : 'lime', fontSize: '1.2rem' }}></i>,
        error: <i className="bi bi-x-circle" style={{ color: props.tema === 'claro' ? 'red' : '#ff7777', fontSize: '1.2rem' }}></i>,
        download: <i className="bi bi-file-arrow-down ml-1 text-green-500"></i>,
        email: <i className="bi bi-envelope-check ml-1"></i>,
        trash: <i className="bi bi-trash3 ml-1 text-red-500"></i>,
        settings: <i className="bi bi-pencil-square" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>,
        disabled: <i className="bi bi-slash-circle" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>,
        alert: <i className="bi bi-exclamation-circle" style={{ fontSize: '1.2rem', color: props.tema === 'claro' ? '#ff9700' : 'yellow' }}></i>,
        clock: <i className="bi bi-stopwatch" style={{ color: props.tema === 'claro' ? 'black' : 'white', fontSize: '1.2rem' }}></i>
    };

    const trClasses = props.tema === 'claro' ? 'hover:bg-neutral-200' : 'hover:bg-neutral-700';

    // Utility functions for formatting data
    const formatFileSize = (size) => {
        const units = ['KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)} ${units[unitIndex]}`;
    };

    const formatFileSizeDrive = (size) => {
        const units = ['KB', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)} ${units[unitIndex]}`;
    };

    const formatarDataComHora = (data) => {
        const dataFormatada = new Date(data);
        const dia = dataFormatada.getDate().toString().padStart(2, '0');
        const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataFormatada.getFullYear().toString();
        return `${dia}/${mes}/${ano}`;
    };

    const formatSeconds = (seconds) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        if (hours > 0) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        } else if (minutes > 0) {
            return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        } else {
            return `00:${remainingSeconds.toString().padStart(2, '0')}`;
        }
    };

    const formataTipo = (numero) => {
        if (numero === 0) {
            return 'Arquivo';
        } else if (numero === 1) {
            return 'Firebird';
        } else if (numero === 2) {
            return 'SQL Server';
        } else if (numero === 5) {
            return 'PostgreSQL';
        }
        return 'Desconhecido';
    };

    // Render task row
    const renderTaskRow = (data) => {
        const handleTaskSelection = (activate) => {
            if (activate) {
                if (!props.carregandoTabela) {
                    props.selecionaTarefa(data.id, parseInt(numeroLinhas), props.inscricao);
                }
            }
        };

        return (
            <tr key={index}
                className={`${trClasses} hover:cursor-pointer ${props.tarefaID === data.id ? props.tema === 'claro' ? 'bg-neutral-200' : 'bg-neutral-700' : ''}`}
                onClick={props.tarefaID !== data.id ? () => handleTaskSelection(true) : undefined}>
                <td className="border-r border-gray-300">
                    {data.backup_ativo === 1 ? icons.disabled : data.em_execucao === 'SIM' ? icons.clock : data.status === 'C' ? icons.correct : data.status === 'A' ? icons.alert : icons.error}
                </td>
                <td className="border-r border-gray-300">{data.nome}</td>
                <td className="border-r border-gray-300">{formatFileSize(data.tamanho)}</td>
                <td className="border-r border-gray-300">{`${formatarDataComHora(data.execucao)} - ${obterDiaDaSemana(formatarDataComHora(data.execucao))}`}</td>
                <td className="border-r border-gray-300">{formatSeconds(data.tempo_total)}</td>
                <td className="border-r border-gray-300">{formataTipo(data.tipo_backup)}</td>
                <td><span onClick={(e) => {
                    e.stopPropagation();
                    props.abreTarefasConfig(data.id, data.nome);
                }}>{icons.settings}</span></td>
            </tr>
        );
    };

    // Render history row
    const renderHistoryRow = (data) => {
        return (
            <tr key={index} className={trClasses}>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>
                    {data.status === 'C' ? icons.correct : data.status === 'A' ? icons.alert : icons.error}
                </td>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>{data.nome}</td>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>
                    {formatFileSizeDrive(data.tamanho > 5000 ? data.tamanho : 0)}
                </td>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>
                    {`${formatarDataComHora(data.data_execucao)} - ${obterDiaDaSemana(formatarDataComHora(data.data_execucao))}`}
                </td>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>
                    {data.hora_execucao}
                </td>
                <td className="cursor-pointer border-r border-gray-300" onClick={() => props.getBackupMensagem(data.id)}>
                    {formatSeconds(data.tempo_total)}
                </td>
                <td className="border-r border-gray-300">
                    {data.tamanho > 5000 ? (
                        <span 
                            className="hover:underline cursor-pointer" 
                            onClick={() => dispatch(downloadArquivo(props.inscricao, data.nome_arquivo, data.senha_arquivo))}
                        > 
                            Baixar {icons.download} 
                        </span>
                    ) : (
                        <span>Sem</span>
                    )}
                </td>
                <td className="border-r border-gray-300">
                    {data.tamanho > 5000 ? (
                        <span 
                            className="hover:underline cursor-pointer" 
                            onClick={() => {
                                props.modalEmail(true);
                                props.setNomeDoArquivo(data.nome_arquivo);
                                dispatch({ type: 'SET_SENHA_ARQUIVO', payload: data.senha_arquivo });
                            }}
                        > 
                            Enviar {icons.email} 
                        </span>
                    ) : (
                        <span>Sem</span>
                    )}
                </td>
                <td>
                    <span 
                        className="hover:underline cursor-pointer" 
                        onClick={() => {
                            props.setTamanhoArquivo(data.tamanho > 5000 ? data.tamanho : 0);
                            props.setIdHistorico(data.id);
                            props.setNomeDoArquivo(data.nome_arquivo);
                            props.setShowModalArquivo(true);
                        }}
                    > 
                        Apagar {icons.trash} 
                    </span>
                </td>
            </tr>
        );
    };

    // Render based on type
    return tipo === 'tarefas' 
        ? renderTaskRow(dados) 
        : tipo === 'historico' 
            ? renderHistoryRow(dados) 
            : null;
}

TableContent.propTypes = {
    dados: PropTypes.object.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tipo: PropTypes.oneOf(['tarefas', 'historico']).isRequired,
    modalEmail: PropTypes.func,
    setTamanhoArquivo: PropTypes.func,
    setShowModalArquivo: PropTypes.func,
    setIdHistorico: PropTypes.func,
    setNomeDoArquivo: PropTypes.func,
    tema: PropTypes.oneOf(['claro', 'escuro']).isRequired,
    tarefaID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numeroLinhas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inscricao: PropTypes.string,
    carregandoTabela: PropTypes.bool,
    selecionaTarefa: PropTypes.func,
    abreTarefasConfig: PropTypes.func,
    getBackupMensagem: PropTypes.func
};

const mapStateToProps = (state) => ({
    tarefaID: state.appReducer.tarefaID,
    dadosTarefas: state.appReducer.dadosTarefas,
    tema: state.appReducer.tema,
    numeroLinhas: state.appReducer.numeroLinhas,
    pagina: state.appReducer.pagina,
    mensagemBackup: state.appReducer.mensagemBackup,
    inscricao: state.appReducer.dadosUsuario.inscricao,
    carregandoTabela: state.appReducer.carregandoTabela
});

const mapDispatchToProps = (dispatch) => ({
    selecionaTarefa: (tarefaNome, numeroLinhas, inscricao) => 
        dispatch(selecionaTarefa(tarefaNome, numeroLinhas, inscricao)),
    abreTarefasConfig: (id, nome) => 
        dispatch(abreTarefasConfig(id, nome)),
    getBackupMensagem: (id) => 
        dispatch(getBackupMensagem(id)),
    downloadArquivo: (inscricao, nomeArquivo) => 
        dispatch(downloadArquivo(inscricao, nomeArquivo))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableContent);