import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { geraChave } from './utilitarios/senhaArquivo';

function ModalDownload(props) {
  const [velocidadeDownload, setVelocidadeDownload] = useState(0);
  // Refs para armazenar último tempo e último valor de bytes recebidos
  const lastTimeRef = useRef(Date.now());
  const lastBytesRef = useRef(props.atual);

  // Refs adicionais para guardar os valores atuais de props.atual e props.total
  // para que sejam acessíveis dentro do callback do setInterval
  const atualRef = useRef(props.atual);
  const totalRef = useRef(props.total);

  useEffect(() => {
    atualRef.current = props.atual;
    totalRef.current = props.total;
  }, [props.atual, props.total]);

  const backgroudClass =
    props.tema === 'escuro'
      ? 'border-neutral-700 bg-dark text-white'
      : 'bg-light';

  const dispatch = useDispatch();

  function formatFileSize(size) {
    // Consideramos que size está em bytes
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }

  function formatTimeRemaining(seconds) {
    if (seconds < 60) {
      return `${Math.ceil(seconds)} segundos`;
    } else if (seconds < 3600) {
      const minutes = Math.ceil(seconds / 60);
      return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
    } else {
      const hours = Math.ceil(seconds / 3600);
      return `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
    }
  }

  function fechaModal() {
    document.title = 'Painel';
    dispatch({
      type: 'EFETUANDO_DOWNLOAD',
      payload: {
        show: false,
        porcentagem: 0,
        total: 0,
        atual: 0,
      },
    });
  }

  // Use um useEffect que roda apenas uma vez, criando um único timer.
  useEffect(() => {
    const updateTimer = setInterval(() => {
      const currentAtual = atualRef.current;
      const currentTotal = totalRef.current;

      if (currentAtual > 0 && currentTotal > 0) {
        const now = Date.now();
        const deltaTime = (now - lastTimeRef.current) / 1000; // em segundos
        const deltaBytes = currentAtual - lastBytesRef.current;

        if (deltaTime > 0) {
          const speed = deltaBytes / deltaTime; // bytes por segundo
          setVelocidadeDownload(speed > 0 ? speed : 0);
        }

        // Atualiza os valores para a próxima medição
        lastTimeRef.current = now;
        lastBytesRef.current = currentAtual;
      }
    }, 500);

    return () => clearInterval(updateTimer);
  }, []);

  return (
    <Modal show={props.show} className="modal-confirmacao">
      <Modal.Header className={`${backgroudClass}`} />

      <Modal.Body className={`${backgroudClass} text-center`}>
        {props.atual <= 0 ? (
          <div>
            <p className="mb-4 text-lg">
              Por favor, aguarde enquanto seu arquivo é carregado...
            </p>
            <Spinner />
          </div>
        ) : props.progresso >= 100 ? (
          <div className="py-4">
            <div className="flex items-center justify-center mb-3">
              <div className="w-16 h-16 rounded-full bg-green-500 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold mb-2">Download Finalizado com Sucesso!</h3>
            <p className="mb-4 text-neutral-300">Seu arquivo foi baixado completamente e está pronto para uso.</p>
            
            <div className="mb-4 mx-auto max-w-md bg-neutral-700/30 p-4 rounded-lg border border-neutral-600/50">
              <div className="mb-2">
                <p className="text-sm text-neutral-400">Tamanho Total</p>
                <p className="font-medium text-neutral-100">{formatFileSize(props.total)}</p>
              </div>
              <div className="mt-3 pt-3 border-t border-neutral-600/50">
                <p className="text-sm text-neutral-400 mb-2">
                  Senha Para Descompactar o arquivo
                </p>
                <p className="font-medium text-neutral-100">
                  {props.senhaArquivo === "" || props.senhaArquivo === null
                    ? geraChave(props.inscricao)
                    : props.senhaArquivo}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="mb-4 text-lg">Efetuando o Download!</p>
            <div className="w-full max-w-md mx-auto mb-6">
              <ProgressBar
                now={props.progresso}
                animated
                className={props.tema === 'escuro' ? 'bg-slate-700' : ''}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 max-w-md mx-auto bg-neutral-700/30 p-4
             rounded-lg border border-neutral-600/50">
              <div className="col-span-2">
                <p className="text-lg text-neutral-200">
                  Progresso:{' '}
                  <span className="font-semibold text-white">{props.progresso}%</span>
                </p>
              </div>
              <div>
                <p className="text-sm text-neutral-400">Total Baixado</p>
                <p className="font-medium text-neutral-100">
                  {formatFileSize(props.atual)}
                </p>
              </div>
              <div>
                <p className="text-sm text-neutral-400">Tamanho Total</p>
                <p className="font-medium text-neutral-100">
                  {formatFileSize(props.total)}
                </p>
              </div>
              <div>
                <p className="text-sm text-neutral-400">Velocidade</p>
                <p className="font-medium text-neutral-100">
                  {(
                    velocidadeDownload /
                    (1024 * 1024)
                  ).toFixed(2)}{' '}
                  MB/s
                </p>
              </div>
              <div>
                <p className="text-sm text-neutral-400">Tempo Restante</p>
                <p className="font-medium text-neutral-100">
                  {velocidadeDownload > 0
                    ? formatTimeRemaining(
                        Math.ceil((props.total - props.atual) / velocidadeDownload)
                      )
                    : 'Calculando...'}
                </p>
              </div>
              <div className="col-span-2 mt-4 pt-4 border-t border-neutral-600/50">
                <p className="text-sm text-neutral-400 mb-2">
                  Senha Para Descompactar o arquivo
                </p>
                <p className="font-medium text-neutral-100">
                  {props.senhaArquivo === "" || props.senhaArquivo === null
                    ? geraChave(props.inscricao)
                    : props.senhaArquivo}
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className={`${backgroudClass} p-3`}>
        {props.progresso >= 100 && (
          <Button
            variant="success"
            onClick={fechaModal}
            className="px-4 py-2 font-medium"
          >
            Fechar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    tema: state.appReducer.tema,
    show: state.appReducer.showModalDownload,
    progresso: state.appReducer.modalDownloadProgresso,
    total: state.appReducer.modalDownloadTamanhoTotal,
    atual: state.appReducer.modalDownloadTamanhoAtual,
    senhaArquivo: state.appReducer.senhaArquivo,
    inscricao: state.appReducer.dadosUsuario.inscricao,
  };
};

export default connect(mapStateToProps, null)(ModalDownload);
