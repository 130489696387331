import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

function ModalDetalhesErros(props) {

    const tableTemaEscuro = 'table-dark table-striped table-bordered table-hover'
    const theadTemaEscuro = ''
    const tbodyTemaEscuro = ''

    const tableTemaClaro = 'table-light table-striped table-bordered table-hover'
    const theadTemaClaro = ''
    const tbodyTemaClaro = ''

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark border-neutral-700 text-white' :
            'bg-light'
    )



    return (
        <Modal show={props.show} onHide={props.handleShow}>
            <Modal.Header closeButton className={backgroudClass}>
                <Modal.Title>Tabela Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body className={backgroudClass}>
                <table className={`table max-h-96 ${props.tema === 'claro' ? tableTemaClaro : tableTemaEscuro} text-center`}>
                    <thead className={props.tema === 'claro' ? theadTemaClaro : theadTemaEscuro}>
                        <tr>
                            <th>Tarefa</th>
                            <th>Dias</th>
                        </tr>
                    </thead>
                    <tbody className={props.tema === 'claro' ? tbodyTemaClaro : tbodyTemaEscuro}>
                        {props.dados[props.index].nome_da_tarefa ? props.dados[props.index].nome_da_tarefa.map((item) => {
                            return (
                                <tr className='font-light'>
                                    <th>{item.nome}</th>
                                    <th>{item.dias}</th>
                                </tr>
                            )
                        }) : ""}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer className={backgroudClass}>
                <Button variant="secondary" onClick={props.handleShow}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema
    }
}

export default connect(mapStateToProps, null)(ModalDetalhesErros)