import React from "react";
import PropTypes from "prop-types";

/**
 * Component for selecting number of items per page
 */
function ItemsPerPageSelector({ value, onChange, tema, options = [10, 20, 50, 100, 1000] }) {
  return (
    <div className="d-flex align-items-center">
      <span className="mt-2 mr-3">Linhas por página:</span>
      <select
        className={
          tema === 'escuro'
            ? "form-select form-select-g text-light bg-black text-base w-20 ml-3"
            : "form-select form-select-g text-dark text-base w-20 ml-3"
        }
        aria-label="Selecionar número de linhas por página"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

ItemsPerPageSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tema: PropTypes.oneOf(['claro', 'escuro']).isRequired,
  options: PropTypes.arrayOf(PropTypes.number)
};

export default ItemsPerPageSelector;