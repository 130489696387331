import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Input from '../components/FormModal';
import axios from 'axios';
import { salvaDadosCliente } from "../redux/actions";
import Alerta from "./Alerta";
import { useNavigate } from 'react-router-dom';
import { MD5 } from "crypto-js";
import apiUrl from "../const";
import { Spinner } from "react-bootstrap";

function Login(props) {
    const [emailInput, setEmailInput] = useState("");
    const [senhaSemCripto, setSenhaSemCripto] = useState("");
    const [carregando, setCarregando] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async () => {
        if (emailInput == '' || senhaSemCripto == '') {
            dispatch({ type: "EXIBE_ALERTA", payload: { show: true, mensagem: "Senha ou usuário inválido", status: 'falha' } });
            return;
        }
        setCarregando(true);
        await axios.post(`${apiUrl}/login`, {
            emailInput,
            senhaInput: MD5(senhaSemCripto).toString().toUpperCase(),
            senhaSemCripto,
        }).then((response) => {
            // Store token based on user type
            if (response.data.dadosUsuario.tipo === "1") {
                localStorage.setItem('TokenMCAdministrador', response.data.token);
            } else {
                localStorage.setItem('token', response.data.token);
            }
            
            props.salvaDadosCliente(response.data);
            if (response.data.dadosUsuario.tipo === "2") {
                navigate('/painel-cliente');
            } else {
                navigate('/painel-multiplos-clientes');
            }
        }).catch((e) => {
            dispatch({ type: "EXIBE_ALERTA", payload: { show: true, mensagem: "Senha ou usuário inválido", status: 'falha' } });
            setCarregando(false);
        });
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const adminToken = localStorage.getItem('TokenMCAdministrador');
        document.title = 'Login';

        if (token || adminToken) {
            axios.post(`${apiUrl}/tokenLogin`, {
                token: adminToken || token
            }).then((response) => {
                props.salvaDadosCliente(response.data);
                document.title = 'Painel';
                if (response.data.dadosUsuario.tipo === "1") {
                    navigate('/painel-multiplos-clientes');
                } else {
                    navigate('/painel-cliente');
                }
            }).catch((e) => {
                navigate('/login');
            });
        } else {
            navigate('/login');
        }
    }, []);

    const isDarkMode = props.tema === 'escuro';

    return (
        <div className={`min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-br ${
            isDarkMode 
                ? 'from-neutral-900 to-neutral-800 text-white' 
                : 'from-blue-50 to-indigo-50 text-neutral-900'
        }`}>
            <div className="max-w-5xl w-full flex flex-col md:flex-row shadow-2xl rounded-3xl overflow-hidden transition-all duration-300">
                {/* Logo Side */}
                <div className={`w-full md:w-2/5 flex items-center justify-center p-8 ${
                    isDarkMode 
                        ? 'bg-gradient-to-br from-neutral-800 to-neutral-900' 
                        : 'bg-gradient-to-br from-white to-blue-50'
                }`}>
                    <div className="relative w-full flex justify-center items-center">
                        <div className={`absolute -z-10 w-64 h-64 rounded-full blur-3xl opacity-20 ${
                            isDarkMode ? 'bg-blue-500' : 'bg-blue-300'
                        }`}></div>
                        <img 
                            src={process.env.PUBLIC_URL + '/logo.png'} 
                            className='w-4/5 max-w-xs transition-all duration-500 hover:scale-110' 
                            alt="Logo"
                        />
                    </div>
                </div>

                {/* Form Side */}
                <div className={`w-full md:w-3/5 p-10 ${
                    isDarkMode 
                        ? 'bg-neutral-800 bg-opacity-95' 
                        : 'bg-white bg-opacity-95'
                }`}>
                    <div className="mb-8 text-center">
                        <h2 className={`text-4xl font-extrabold ${
                            isDarkMode ? 'text-white' : 'text-neutral-900'
                        } tracking-tight`}>
                            Bem-vindo
                        </h2>
                        <p className={`mt-3 text-sm ${
                            isDarkMode ? 'text-neutral-400' : 'text-neutral-500'
                        }`}>
                            Faça login para acessar o painel
                        </p>
                    </div>

                    <Alerta />

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleLogin();
                        }}
                        className="space-y-6"
                    >
                        <div className="space-y-2">
                            <Input
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                                type="email"
                                label="Email"
                                placeholder="Seu endereço de email"
                                className={`w-full px-4 py-3 rounded-xl text-lg transition-all duration-300 ${
                                    isDarkMode 
                                        ? 'bg-neutral-700 border-neutral-600 focus:border-blue-500 focus:ring-blue-500/30' 
                                        : 'bg-neutral-50 border-neutral-300 focus:border-blue-500 focus:ring-blue-500/20'
                                }`}
                            />
                        </div>
                        
                        <div className="space-y-2">
                            <Input
                                value={senhaSemCripto}
                                onChange={(e) => setSenhaSemCripto(e.target.value)}
                                type="password"
                                label="Senha"
                                placeholder="Sua senha"
                                className={`w-full px-4 py-3 rounded-xl text-lg transition-all duration-300 ${
                                    isDarkMode 
                                        ? 'bg-neutral-700 border-neutral-600 focus:border-blue-500 focus:ring-blue-500/30' 
                                        : 'bg-neutral-50 border-neutral-300 focus:border-blue-500 focus:ring-blue-500/20'
                                }`}
                            />
                        </div>

                        <div className="pt-2">
                            <button
                                type="submit"
                                disabled={carregando}
                                className={`w-full flex justify-center items-center py-2 px-6 border border-transparent rounded-xl shadow-lg text-lg font-semibold text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 transform hover:scale-[1.02] ${
                                    carregando ? 'opacity-70 cursor-not-allowed' : ''
                                }`}
                            >
                                {carregando ? (
                                    <>
                                        <Spinner className="animate-spin h-5 w-5 mr-3" /> 
                                        <span>Entrando...</span>
                                    </>
                                ) : (
                                    "Entrar"
                                )}
                            </button>
                        </div>
                    </form>

                    <div className="mt-8 text-center">
                        <a
                            href="https://api.whatsapp.com/send?phone=5547999891745"
                            className={`text-sm font-medium ${
                                isDarkMode 
                                    ? 'text-blue-400 hover:text-blue-300' 
                                    : 'text-blue-600 hover:text-blue-800'
                            } transition-colors duration-300 flex items-center justify-center gap-2 group`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-transform duration-300 group-hover:scale-110" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                            </svg>
                            <span className="underline-offset-2 hover:underline">Precisa de ajuda? Clique aqui!</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        logado: state.appReducer.logado,
        dadosUsuario: state.appReducer.dadosUsuario,
        numeroLinhas: state.appReducer.numeroLinhas
    };
};

const mapDispatchToProps = { salvaDadosCliente };

export default connect(mapStateToProps, mapDispatchToProps)(Login);