import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/**
 * Generic Table component with theme support
 */
function Table({ children, tipo, tema, onSort }) {
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    // Theme configurations
    const themeClasses = {
        dark: {
            table: 'table-dark table-striped table-bordered table-hover',
            thead: '',
            tbody: ''
        },
        light: {
            table: 'table-light table-striped table-bordered table-hover',
            thead: '',
            tbody: ''
        }
    };

    // Get theme classes based on current theme
    const currentTheme = tema === 'claro' ? themeClasses.light : themeClasses.dark;
    
    // Define table structures based on table type
    const tableConfigs = {
        listaBackups: {
            headers: [
                "", "Nome", "Tamanho", "Última Execução", 
                "Tempo Total", "Tipo Do Backup", ""
            ],
            sortable: [
                false, true, true, true, true, true, false
            ],
            sortKeys: [
                null, "nome", "tamanho", "execucao", "tempo_total", "tipo_backup", null
            ]
        },
        histBackups: {
            headers: [
                "", "Nome", "Tamanho", "Dia", "Hora Da Execução", 
                "Tempo", "Download", "Enviar Por Email", "Deletar"
            ],
            sortable: [
                false, true, true, true, true, true, false, false, false
            ],
            sortKeys: [
                null, "nome", "tamanho", "data_execucao", "hora_execucao", "tempo_total", null, null, null
            ]
        }
    };
    
    // If no supported type is provided, return empty div
    if (!tableConfigs[tipo]) {
        return <div className="mb-2"></div>;
    }
    
    // Get headers for the table type
    const headers = tableConfigs[tipo].headers;
    const sortable = tableConfigs[tipo].sortable;
    const sortKeys = tableConfigs[tipo].sortKeys;

    // Handle column header click for sorting
    const handleHeaderClick = (index) => {
        if (!sortable[index] || !onSort) return;
        
        const key = sortKeys[index];
        if (!key) return;
        
        let newDirection;
        if (sortColumn === key) {
            // Toggle direction if same column
            newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            // Default to ascending for new column
            newDirection = 'asc';
        }
        
        setSortColumn(key);
        setSortDirection(newDirection);
        onSort(key, newDirection);
    };

    // Render sort indicator
    const renderSortIndicator = (index) => {
        if (!sortable[index]) return null;
        
        const key = sortKeys[index];
        if (sortColumn === key) {
            return (
                <span className="ml-1">
                    {sortDirection === 'asc' 
                        ? <i className="bi bi-caret-up-fill"></i> 
                        : <i className="bi bi-caret-down-fill"></i>}
                </span>
            );
        }
        return <span className="ml-1 text-gray-400"><i className="bi bi-arrow-down-up"></i></span>;
    };

    return (
        <div className="mb-2">
            <table className={`table table-bordered max-h-96 ${currentTheme.table} text-center`}>
                <thead className={currentTheme.thead}>
                    <tr>
                        {headers.map((header, index) => (
                            <th 
                                key={index} 
                                onClick={() => handleHeaderClick(index)} 
                                className={sortable[index] ? "cursor-pointer hover:bg-opacity-80" : ""}
                            >
                                <div className="flex justify-center items-center">
                                    <span>{header}</span>
                                    {sortable[index] && renderSortIndicator(index)}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={tipo === 'histBackups' ? "max-h-72 overflow-auto" : currentTheme.tbody}>
                    {children}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = {
    children: PropTypes.node,
    tipo: PropTypes.oneOf(['listaBackups', 'histBackups']).isRequired,
    tema: PropTypes.oneOf(['claro', 'escuro']).isRequired,
    onSort: PropTypes.func
};

const mapStateToProps = (state) => ({
    tema: state.appReducer.tema
});

export default connect(mapStateToProps)(Table);
