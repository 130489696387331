import { MD5 } from 'crypto-js';

export function geraChave(inscricao) {

    const chaveCompactada = ' jrwlmyaegd';
    let inscricaoCalc = "";

    // Calcula a nova inscrição
    for (let i = 0; i < inscricao.length; i++) {
        let valor = parseInt(inscricao[i]);
        valor = (valor + 4) * 12;
        inscricaoCalc += valor;
    }

    let inscricaoCrip = "";
    
    // Criptografa a inscrição utilizando a chave compactada
    for (let i = 0; i < inscricaoCalc.length; i++) {
        inscricaoCrip += chaveCompactada[parseInt(inscricaoCalc[i]) + 1];
    }

    // Gera o hash MD5 da inscrição criptografada
    const chavemd5 = MD5(inscricaoCrip);
    return chavemd5.toString().toUpperCase();
}

