import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { atulizaTarefa } from "../redux/actions";
import { alteraNumeroDeLinhas, trocaPagina } from "../redux/actions";
import MensagemModal from "./MensagemModal";
import ModalEnvioEmail from "./ModalEnvioEmail";
import ModalApagarArquivo from "./ModalApagarArquivo";
import ConfigModalTarefas from "./ConfigModalTarefas";
import TaskList from "./TaskList";
import HistoryList from "./HistoryList";
import TaskActions from "./TaskActions";
import ItemsPerPageSelector from "./ItemsPerPageSelector";
import Pagination from "./Pagination";
import PropTypes from "prop-types";

/**
 * Main container component for backup tasks management
 */
function BackupTasksContainer(props) {
    // State for modals and UI
    const [showModalArquivo, setShowModalArquivo] = useState(false);
    const [tamanhoArquivo, setTamanhoArquivo] = useState(0);
    const [nomeDoArquivo, setNomeDoArquivo] = useState('');
    const [idHistorico, setIdHistorico] = useState(0);
    const [showNovaModal, setShowNovaModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    
    const dispatch = useDispatch();

    // Handlers
    const handleRefreshTasks = () => {
        props.atulizaTarefa([props.usuario, props.senha]);
    };

    const handleNewTask = () => {
        setShowNovaModal(true);
    };

    const handleCloseNewTaskModal = () => {
        setShowNovaModal(false);
    };

    const handleItemsPerPageChange = (value) => {
        dispatch(alteraNumeroDeLinhas(value, props.tarefaID, props.inscricao));
    };

    const handlePageChange = (pageNumber) => {
        props.trocaPagina(props.tarefaID, parseInt(props.numeroLinhas), pageNumber, props.inscricao);
    };

    return (
        <div className='container'>
            {/* Task List Section */}
            <div className="flex justify-between items-center mt-4 mb-2">
                <div>
                    <h1 className="text-xl">Tarefas</h1>
                </div>
                <TaskActions 
                    onNewTask={handleNewTask}
                    onRefresh={handleRefreshTasks}
                    tema={props.tema}
                />
            </div>
            
            <TaskList 
                tasks={props.dadosTarefas}
                isLoading={props.carregandoTabelaDeTarefas}
            />

            {/* History Section */}
            <h1 className="text-xl mt-4 mb-2">Histórico Do Backup</h1>
            
            {/* Modals */}
            <MensagemModal />
            <ModalEnvioEmail 
                show={showEmailModal} 
                setShow={setShowEmailModal} 
                tema={props.tema} 
                nomeDoArquivo={nomeDoArquivo}
            />
            <ModalApagarArquivo 
                show={showModalArquivo} 
                handleShow={setShowModalArquivo} 
                idHistorico={idHistorico} 
                tamanhoArquivo={tamanhoArquivo} 
                nomeDoArquivo={nomeDoArquivo}
            />
            <ConfigModalTarefas 
                show={showNovaModal} 
                abreFecha={handleCloseNewTaskModal} 
                modoNovo={true} 
            />

            {/* History Table */}
            <HistoryList 
                historyData={props.historicoTarefa}
                isLoading={props.carregandoTabela}
                taskId={props.tarefaID}
                onEmailModal={setShowEmailModal}
                onSetFileSize={setTamanhoArquivo}
                onShowDeleteModal={setShowModalArquivo}
                onSetHistoryId={setIdHistorico}
                onSetFileName={setNomeDoArquivo}
            />

            {/* Pagination */}
            <div className="d-flex justify-content-between mb-5">
                <div className="d-flex w-2/4">
                    <ItemsPerPageSelector 
                        value={parseInt(props.numeroLinhas)}
                        onChange={handleItemsPerPageChange}
                        tema={props.tema}
                    />
                </div>
                <div>
                    <Pagination 
                        currentPage={props.pagina}
                        totalItems={props.totalDeLinhas}
                        itemsPerPage={parseInt(props.numeroLinhas)}
                        onPageChange={handlePageChange}
                        tema={props.tema}
                    />
                </div>
            </div>
        </div>
    );
}

BackupTasksContainer.propTypes = {
    dadosTarefas: PropTypes.array,
    historicoTarefa: PropTypes.array,
    carregandoTabela: PropTypes.bool.isRequired,
    carregandoTabelaDeTarefas: PropTypes.bool.isRequired,
    numeroLinhas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    pagina: PropTypes.number.isRequired,
    totalDeLinhas: PropTypes.number.isRequired,
    tema: PropTypes.string.isRequired,
    tarefaID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    usuario: PropTypes.string,
    senha: PropTypes.string,
    inscricao: PropTypes.string,
    atulizaTarefa: PropTypes.func.isRequired,
    trocaPagina: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    nomeTarefaSelecionada: state.appReducer.nomeTarefaSelecionada,
    tarefaID: state.appReducer.tarefaID,
    dadosTarefas: state.appReducer.dadosTarefas,
    historicoTarefa: state.appReducer.historicoTarefa,
    numeroLinhas: state.appReducer.numeroLinhas,
    totalDeLinhas: state.appReducer.totalDeLinhas,
    tema: state.appReducer.tema,
    carregandoTabela: state.appReducer.carregandoTabela,
    carregandoTabelaDeTarefas: state.appReducer.carregandoTabelaDeTarefas,
    pagina: state.appReducer.pagina,
    usuario: state.appReducer.dadosUsuario.usuario,
    senha: state.appReducer.dadosUsuario.senha,
    inscricao: state.appReducer.dadosUsuario.inscricao,
});

const mapDispatchToProps = (dispatch) => ({
    trocaPagina: (tarefaID, numeroLinhas, pagina, inscricao) => 
        dispatch(trocaPagina(tarefaID, numeroLinhas, pagina, inscricao)),
    atulizaTarefa: (data) => 
        dispatch(atulizaTarefa(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupTasksContainer);