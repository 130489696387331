import axios from "axios";
import { MD5 } from 'crypto-js';
import apiUrl from "../const";

// Action para selecionar uma tarefa e carregar seu histórico
export function selecionaTarefa(tarefaID, numeroLinhas, inscricao) {
    return async (dispatch) => {
        try {
            // Define a página como 1 ao selecionar uma nova tarefa
            dispatch({ type: "TROCA_PAGINA", payload: 1 });
            dispatch({ type: "CARREGANDO_HISTORICO_TAREFA" });
            dispatch({ type: "SLECIONA_TAREFAID", payload: tarefaID });
            const response = await axios.post(
                `${apiUrl}/getTarefaHistorico`,
                { tarefaID, numeroLinhas, pagina: 0, inscricao }
            );
            dispatch({
                type: "HISTORICO_TAREFA",
                payload: response.data.historicoTarefa,
            });
            dispatch({
                type: "ALTERA_TOTAL_DE_LINHAS",
                payload: response.data.totalDeLinhas[0]["COUNT(*)"],
            });
        } catch (error) {
            dispatch({
                type: "HISTORICO_TAREFA",
                payload: [],
            });
            console.log(error)
        }
    };
}

// Action para trocar o tema
export function trocaTema(tema) {
    return {
        type: "TROCA_TEMA",
        payload: tema,
    };
}

// Action para abrir a tela de configurações
export function abreConfig() {
    return {
        type: "ABRE_CONFIG",
        payload: true,
    };
}

// Action para fechar a tela de configurações
export function fechaConfig() {
    return {
        type: "FECHA_CONFIG",
        payload: false,
    };
}

// Action para abrir a tela de configurações de tarefas
export function abreTarefasConfig(tarefaID, nomeTarefa) {
    return async (dispatch) => {
        try {
            dispatch({ type: "CARREGANDO_CONFIG_TAREFAS", payload: true });
            dispatch({ type: "NOME_TAREFA_SELECIONADA", payload: nomeTarefa });
            dispatch({ type: "ABRE_TAREFAS_CONFIG", payload: true });
            const response = await axios.post(
                `${apiUrl}/getConfiguracaoTarefa`,
                { tarefaID }
            );
            dispatch({
                type: "GET_CONFIGURACOES_TAREFA",
                payload: response.data.configTarefa,
            });
            dispatch({ type: "CARREGANDO_CONFIG_TAREFAS", payload: false });
        } catch (error) {
            console.log(error);
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    };
}

// Action para fechar a tela de configurações de tarefas
export function fechaTarefasConfig() {
    return {
        type: "FECHA_TAREFAS_CONFIG",
        payload: false,
    };
}

// Action para salvar os dados do cliente
export function salvaDadosCliente(dados) {
    return {
        type: "SALVA_DADOS_CLIENTE",
        payload: dados,
    };
}

// Action para carregar o histórico de uma tarefa específica
export function getHistoricoTarefa(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: "CARREGANDO_HISTORICO_TAREFA_ESPECIFICA" });
            const response = await axios.post(
                `${apiUrl}/getTarefaHistoricoEspecifico`,
                { id }
            );
            dispatch({
                type: "HISTORICO_TAREFA",
                payload: response.data.historicoTarefa,
            });
            dispatch({
                type: "ALTERA_TOTAL_DE_LINHAS",
                payload: response.data.totalDeLinhas[0]["COUNT(*)"],
            });
        } catch (error) {
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    };
}

// Action para alterar o número de linhas por página
export function alteraNumeroDeLinhas(numeroLinhas, tarefaID, inscricao) {
    if (tarefaID) {
        return async (dispatch) => {
            try {
                dispatch({ type: "CARREGANDO_HISTORICO_TAREFA" });
                const response = await axios.post(
                    `${apiUrl}/getTarefaHistorico`,
                    { tarefaID, numeroLinhas: parseInt(numeroLinhas), pagina: 0, inscricao }
                );
                dispatch({
                    type: "HISTORICO_TAREFA",
                    payload: response.data.historicoTarefa,
                });
                dispatch({
                    type: "ALTERA_NUMERO_DE_LINHAS",
                    payload: numeroLinhas,
                });
                dispatch({ type: "TROCA_PAGINA", payload: 1 });
            } catch (error) {
                dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
            }
        };
    } else {
        return { type: "ALTERA_NUMERO_DE_LINHAS", payload: numeroLinhas };
    }
}

// Action para trocar a página do histórico de uma tarefa
export function trocaPagina(tarefaID, numeroLinhas, pagina, inscricao) {
    const paginaNumero = pagina === 1 ? 0 : (pagina - 1) * numeroLinhas;

    if (tarefaID) {
        return async (dispatch) => {
            try {
                dispatch({ type: "TROCA_PAGINA", payload: pagina });
                dispatch({ type: "CARREGANDO_HISTORICO_TAREFA" });
                const resposta = await axios.post(`${apiUrl}/getTarefaHistorico`, {
                    tarefaID,
                    numeroLinhas: parseInt(numeroLinhas),
                    pagina: paginaNumero,
                    inscricao
                });
                dispatch({
                    type: "HISTORICO_TAREFA",
                    payload: resposta.data.historicoTarefa,
                });
            } catch (error) {
                dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
            }
        };
    } else {
        return { type: "ALTERA_NUMERO_DE_LINHAS", payload: numeroLinhas };
    }
}

// Action para obter o backup de uma mensagem específica
export function getBackupMensagem(historicoID) {
    return async (dispatch) => {
        try {
            dispatch({ type: "CARREGANDO_BACKUP_MENSAGEM" });
            const response = await axios.post(
                `${apiUrl}/getTarefaHistoricoEspecifico`,
                { historicoID }
            );
            dispatch({ type: "BACKUP_MENSAGEM", payload: response.data.resultado });
        } catch (error) {
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    };
}

// Action para obter as configurações de uma tarefa
export function getTarefaConfigs(tarefaID) {
    return async (dispatch) => {
        try {
            dispatch({ type: "CARREGANDO_CONFIG_TAREFAS" });
            const response = await axios.post(
                `${apiUrl}/getTarefaHistoricoEspecifico`,
                { tarefaID }
            );
            dispatch({
                type: "GET_CONFIGURACOES_TAREFA",
                payload: response.data.configTarefa,
            });
        } catch (error) {
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    };
}

// Action para fechar a tela de backup de mensagem
export function FechaMensagemBackup() {
    return {
        type: "FECHA_BACKUP_MENSAGEM",
    };
}

// Action para realizar o logout
export function deslogar() {
    localStorage.removeItem("token");
    return {
        type: "DESLOGAR",
    };
}

// Action para atualizar as configurações de uma tarefa
export function atualizaTarefaConfig(configuracoes) {
    return async (dispatch) => {
        try {
            dispatch({ type: "ATULIZANDO_TAREFA_CONFIG" });
            await axios.post(
                `${apiUrl}/atualizarConfiguracoesTarefa`,
                configuracoes
            );
            dispatch({ type: "ATULIZANDO_TAREFA_CONFIG" });
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Atualizado com sucesso", status: 'sucesso'}})
        } catch (error) {
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Erro ao atualiza", status: 'erro'}})
        }
    };
}

// Action para ativar o backup de uma tarefa específica
export function ativaBackup(id) {
    return async (dispatch) => {
        try {
            await axios.post(`${apiUrl}/ativaBackup`, { id });
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "O backup será ativado em breve", status: 'sucesso'}})
        } catch (error) {
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Erro ao ativar o backup", status: 'erro'}})
        }
    };
}

// Salva apenas quando ouver mudança na configuração dos emails
export function salvaEmailConfiguracao(objeto, id) {
    return async (dispatch) => {
        try {
            await axios.post(`${apiUrl}/atualizaConfigsEmail`, { dados: objeto });
            const response = await axios.post(`${apiUrl}/atualizaDadosUsuario`, { id: id });
            dispatch({ type: "DADOS_USUARIO_ATULIZADOS", payload: response })
        }
        catch (error) {
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    }
}

// Atuliza a senha e as configurações de email
export function atulizaSenhaUsuario(dadosUsuario, senha) {
    const senhaCriptografada = MD5(senha).toString().toUpperCase();
    return async (dispatch) => {
        try {
            await axios.post(`${apiUrl}/atualizaSenha`, { dadosUsuario: dadosUsuario, senhaNova: senhaCriptografada });
            const response = await axios.post(`${apiUrl}/atualizaDadosUsuario`, { id: dadosUsuario.id });
            dispatch({ type: "DADOS_USUARIO_ATULIZADOS", payload: response })
        } catch (erro) {
            dispatch({ type: "FETCH_DATA_FAILURE", payload: erro.message });
        }
    }
}

// Atuliza os dados do usuário quando os dados forem modificados
export function atulizaDadosUsuario(id) {
    return async (dispatch) => {
        console.log('ativado')
        try {
            dispatch({ type: "ATULIZANDO_DADOS_USUARIOS", payload: true })
            const response = await axios.post(`${apiUrl}/atualizaDadosUsuario`, { id: id });
            dispatch({ type: "ATULIZANDO_DADOS_USUARIOS", payload: false })
            dispatch({ type: "DADOS_USUARIO_ATULIZADOS", payload: response })
        }
        catch (error) {
            console.log(error)
            dispatch({ type: "FETCH_DATA_FAILURE", payload: error.message });
        }
    }
}

// função para atulizar as tarefas usando o usuario e a senha
export function atulizaTarefa(dados) {
    return async (dispatch) => {
        dispatch({ type: "CARREGANDO_TABELA_DE_TAREFAS", payload: true })
        try {
            const response = await axios.post(`${apiUrl}/atualizaTarefas`, { usuario: dados[0], senha: dados[1] })

            dispatch({ type: "AUTALIZA_DADOS_TAREFAS", payload: response.data });
            dispatch({ type: "CARREGANDO_TABELA_DE_TAREFAS", payload: false })
        } catch (error) {
            console.log(error)
        }
    }
}

export async function reiniciaUpCloud(id) {
    try {
        await axios.post(`${apiUrl}/reiniciaUpCloud`, { id: id })
    } catch (error) {
        console.log(error)
    }
}

export async function alterarNomeTarefa(nome, id) {
    try {
        await axios.post(`${apiUrl}/atualizaNomeTarefa`, { nome: nome, id: id })
    } catch (error) {
        console.log(error)
    }
}

export function downloadArquivo(inscricao, nomeArquivo, senhaArquivo) {
    return async (dispatch) => {
        dispatch({type: "EFETUANDO_DOWNLOAD", payload: {show: true, porcentagem: 0, total: 0, atual: 0, senhaArquivo: senhaArquivo}})
        try {
            let downloadProgress = {
                percent: 0,
                total: 0,
                loaded: 0,
            };
            
            const response = await axios.post(`${apiUrl}/downloadArquivo`, { inscricao, nomeArquivo }, {
                responseType: 'blob', // Configura o axios para receber a resposta como um Blob
                timeout: 999999999999999999999,
                onDownloadProgress: (progressEvent) => {
                    // Calcula o progresso em porcentagem
                    downloadProgress.percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            
                    // Armazena o tamanho total e o tamanho já percorrido
                    downloadProgress.total = progressEvent.total;
                    downloadProgress.loaded = progressEvent.loaded;
                    
                    dispatch({type: "EFETUANDO_DOWNLOAD", payload: {
                        show: true,
                        porcentagem: downloadProgress.percent, 
                        total: downloadProgress.total, 
                        atual: downloadProgress.loaded,
                        senhaArquivo: senhaArquivo
                    }})

                    document.title = `Baixando ${downloadProgress.percent}%`
                    
                    if(downloadProgress.percent == 100) {
                        document.title = `Download Finalizado`
                    }

                }
            });
            
            // Cria uma URL temporária para o arquivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // Cria um link de download
            const a = document.createElement('a');
            a.href = url;
            a.download = nomeArquivo;
            document.body.appendChild(a);
            a.click();
            a.remove();
            
            // Libera a URL temporária
            window.URL.revokeObjectURL(url);

            dispatch({type: "MODAL_CARREGANDO", payload: {show: false, texto: ""}})
        } catch (error) {
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Ouve um erro entre em contato com o suporte", status: 'falha'}})
            console.error('Erro ao baixar o arquivo:', error);
            dispatch({type: "MODAL_CARREGANDO", payload: {show: false, texto: ""}})
        }
    }
}



export function deleteArquivo(inscricao, nomeArquivo, tamanho, id, tarefaID, numeroLinhas, pagina) {
    return async (dispatch) => {
        const paginaNumero = pagina === 1 ? 0 : (pagina - 1) * numeroLinhas;

        console.log(numeroLinhas, pagina)

        dispatch({type: "MODAL_CARREGANDO", payload: {show: true, texto: "Aguarde enquanto o seu arquivo é deletado"}})

        if (tamanho > 0){
            await axios.post(`https://upcloud.net.br:14014/api/drivep/file/del`, { folder: inscricao, file: nomeArquivo })    
        }

        await axios.post(`${apiUrl}/deleteArquivo`, { folder: inscricao, file: nomeArquivo, id: id, tamanho: tamanho })

        try {
            const dadosHistorico = await axios.post(
                `${apiUrl}/getTarefaHistorico`,
                { tarefaID: tarefaID, numeroLinhas: numeroLinhas, pagina: paginaNumero, inscricao: inscricao }
            );

            dispatch({
                type: "HISTORICO_TAREFA",
                payload: dadosHistorico.data.historicoTarefa,
            });
            dispatch({
                type: "ALTERA_TOTAL_DE_LINHAS",
                payload: dadosHistorico.data.totalDeLinhas[0]["COUNT(*)"],
            });

            dispatch({type: "MODAL_CARREGANDO", payload: {show: false, texto: ""}})
            return true
        } catch (error) {
            dispatch({
                type: "HISTORICO_TAREFA",
                payload: [],
            });
            dispatch({type: "MODAL_CARREGANDO", payload: {show: false, texto: ""}})
            console.error('Erro:', error);
        }
    }
}

export function enviaEmail(inscricao, nomeDoArquivo, email, senha) {
    return async (dispatch) => {
        dispatch({type: 'ENVIO_BACKUP_EMAIL', payload: {enviando: true, texto:''}})
        try{
            await axios.post(`${apiUrl}/enviaBackupEmail`, {inscricao: inscricao, nomeDoArquivo: nomeDoArquivo, destino: email, senhaArquivo: senha});
            dispatch({type: 'ENVIO_BACKUP_EMAIL', payload: {enviando: false, texto:'sucesso'}})
        } catch (error){
            console.log(error)
            dispatch({type: 'ENVIO_BACKUP_EMAIL', payload: {enviando: false, texto:'erro'}})
        }
    }
}

// Action para adicionar uma nova tarefa
export function adicionarTarefa(dadosTarefa) {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADICIONANDO_TAREFA" });
            const response = await axios.post(
                `${apiUrl}/adicionarTarefa`,
                dadosTarefa
            );
            
            if (response.data.success) {
                dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Tarefa adicionada com sucesso", status: 'sucesso'}});
                // Atualiza a lista de tarefas
                const dadosUsuario = response.data.dadosUsuario || {};
                dispatch(atulizaTarefa([dadosUsuario.usuario, dadosUsuario.senha]));
            } else {
                dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Erro ao adicionar tarefa", status: 'erro'}});
            }
            
            return response.data;
        } catch (error) {
            console.log(error);
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Erro ao adicionar tarefa", status: 'erro'}});
            return { success: false, error: error.message };
        }
    };
}