const temaInicial = localStorage.getItem('tema') || 'escuro';
const initialState = {
    tema: temaInicial,
    dadosUsuario: {
        id: '',
        inscricao: '',
        nome: '',
        usuario: '',
        senha: '',
        email: '',
        estado_id: '',
        cidade_id: '',
        tipo: '',
        ultimo_acesso: '',
        versao: '',
        email_responsavel: '',
        status: '',
        telefone: '',
        contato: '',
        whatsapp: '',
        observacao: '',
        usuario_adm: '',
        dados_contratada: '',
        plano_contratado: '',
        contrato_assinado: '',
        horario_download: '',
        reinicia_upcloud: '',
        plano: '',
    },
    dadosTarefas: [],
    tamanho_usado: 0,
    historicoTarefa: [],
    logado: false,
    numeroLinhas: 50,
    pagina: 1,
    tarefaID: null,
    totalDeLinhas: null,
    carregandoTabelaDeTarefas: false,
    carregandoTabela: false,
    carregandoConfigTarefa: false,
    showTarefasConfig: false,
    showModalCarregnado: false,
    ModalCarregandoTexto: '',
    mensagemBackup: '',
    configTarefa: [""],
    nomeTarefaSelecionada: '',
    showBackupMensagem: false,
    showAlerta: false,
    alertaMensagem: '',
    alertaStatus: '',
    showModalDownload: false,
    modalDownloadProgresso: 0,
    modalDownloadTamanhoTotal: 0,
    modalDownloadTamanhoAtual: 0,
    senhaArquivo: '',
    enviandoEmailBackup: false,
    statusEnvioEmail: ''
};

function reducer(state = initialState, action) {
    switch (action.type) {

        case 'DESLOGAR':
            return initialState

        case 'TROCA_TEMA':
            const novoTema = state.tema === 'claro' ? 'escuro' : 'claro';
            localStorage.setItem('tema', novoTema); // Adiciona tema no localStorage
            return {
                ...state, tema: novoTema
            };

        case 'SALVA_DADOS_CLIENTE':
            return {
                ...state,
                dadosUsuario: action.payload.dadosUsuario,
                dadosTarefas: action.payload.dadosTarefas,
                tamanho_usado: action.payload.totalSizeUsed,
                logado: true
            }

        case 'CARREGANDO_HISTORICO_TAREFA':
            return {
                ...state,
                carregandoTabela: true
            }

        case 'HISTORICO_TAREFA':
            return {
                ...state,
                historicoTarefa: action.payload,
                carregandoTabela: false
            }
        case 'CARREGANDO_TABELA_DE_TAREFAS':
            return {
                ...state,
                carregandoTabelaDeTarefas: action.payload,
            }
        case 'TROCA_PAGINA':
            return {
                ...state,
                pagina: action.payload
            }

        case 'ALTERA_NUMERO_DE_LINHAS':
            return {
                ...state,
                numeroLinhas: action.payload
            }

        case 'SLECIONA_TAREFAID':
            return {
                ...state,
                tarefaID: action.payload
            }

        case 'SELECIONA_TAREFA':
            return {
                ...state,
                tarefaID: action.payload
            }

        case 'CARREGANDO_CONFIG_TAREFAS':
            return {
                ...state,
                carregandoConfigTarefa: action.payload
            }

        case 'ALTERA_TOTAL_DE_LINHAS':
            return {
                ...state,
                totalDeLinhas: action.payload
            }

        case 'ABRE_TAREFAS_CONFIG':
            return {
                ...state,
                showTarefasConfig: action.payload
            }

        case 'FECHA_TAREFAS_CONFIG':
            return {
                ...state,
                showTarefasConfig: action.payload
            }

        case 'CARREGANDO_BACKUP_MENSAGEM':
            return {
                ...state,
                showBackupMensagem: true,
                mensagemBackup: 'carregando...'
            }

        case 'BACKUP_MENSAGEM':
            let mensagemPayload = action.payload
            let mensagemEl = []

            if (mensagemPayload.length > 0) {
                mensagemPayload.map(item => {
                    mensagemEl.push(item.mensagem)
                })
            } else {
                mensagemEl = ''
            }

            return {
                ...state,
                mensagemBackup: mensagemEl
            }

        case 'GET_CONFIGURACOES_TAREFA':
            return {
                ...state,
                configTarefa: action.payload
            }

        case 'NOME_TAREFA_SELECIONADA':
            return {
                ...state,
                nomeTarefaSelecionada: action.payload
            }

        case 'FECHA_BACKUP_MENSAGEM': {
            return {
                ...state,
                showBackupMensagem: false
            }
        }

        case 'DADOS_USUARIO_ATULIZADOS': {
            return {
                ...state,
                dadosUsuario: action.payload.data.dadosNovos[0]
            }
        }

        case 'AUTALIZA_DADOS_TAREFAS': {
            return {
                ...state,
                historicoTarefa: [],
                dadosTarefas: action.payload.dadosTarefas,
                tarefaID: null
            }
        }

        case 'MODAL_CARREGANDO': {
            return {
                ...state,
                showModalCarregnado: action.payload.show,
                ModalCarregandoTexto: action.payload.texto,
            }
        }

        case 'EXIBE_ALERTA': {
            return {
                ...state,
                showAlerta: action.payload.show,
                alertaMensagem: action.payload.mensagem,
                alertaStatus: action.payload.status,
            }
        }

        case 'EFETUANDO_DOWNLOAD': {
            return {
                ...state,
                showModalDownload: action.payload.show,
                modalDownloadProgresso: action.payload.porcentagem,
                modalDownloadTamanhoTotal: action.payload.total,
                modalDownloadTamanhoAtual: action.payload.atual,
                senhaArquivo: action.payload.senhaArquivo
            }
        }

        case 'SET_SENHA_ARQUIVO': {
            return {
                ...state,
                senhaArquivo: action.payload
            }
        }

        case 'ENVIO_BACKUP_EMAIL':{
            return{
                ...state,
                enviandoEmailBackup: action.payload.enviando,
                statusEnvioEmail: action.payload.texto,
            }
        }

        default:
            return state;
    }
}

export default reducer;
