const initialState = {
    dadosCliientes: [
        {
            id: 0,
            inscricao: '',
            nome: '',
            usuario: '',
            senha: '',
            email: '',
            estado_id: 1,
            cidade_id: 1,
            tipo: '2',
            ultimo_acesso: '',
            versao: '',
            email_responsavel: '',
            status: '',
            telefone: '',
            contato: '',
            whatsapp: '',
            observacao: '',
            usuario_adm: 0,
            dados_contratada: '',
            plano_contratado: '',
            contrato_assinado: '0',
            horario_download: '0',
            reinicia_upcloud: 'N'
        }
    ],
    dadosHistorico: [{
        data_execucao: "",
        data_expiracao: null,
        em_execucao: "NAO",
        execucao: "",
        executa: 0,
        hora_execucao: "",
        id: 0,
        nome: "",
        nome_arquivo: "",
        senha_arquivo: "",
        status: "",
        tamanho: 1,
        tarefa_id: 1,
        tempo_total: 1,
        tipo_backup: null,
        usuario_id: 1
    }],
    carregandoTabelaClientes: false,
    carregandoTabelaHistoricoCliente: false,
    // Add new state properties for client tasks
    tarefasCliente: [],
    carregandoTarefasCliente: false,
    // Adiciona estados para controle de adição de cliente
    adicionandoCliente: false,
    clienteAdicionado: false,
};

const MCReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CARREGANDO_TABELA_DE_CLIENTES':
            return {
                ...state,
                carregandoTabelaClientes: action.payload
            };
        case 'SALVA_DADOS_CLIENTES':
            return {
                ...state,
                dadosCliientes: action.payload
            };
        case 'CARREGANDO_TABELA_HISTORICO_CLIENTE':
            return {
                ...state,
                carregandoTabelaHistoricoCliente: action.payload
            };
        case 'SALVA_DADOS_HISTORICO_CLIENTE':
            return {
                ...state,
                dadosHistorico: action.payload
            };
        // Add new cases for client tasks
        case 'CARREGANDO_TAREFAS_CLIENTE':
            return {
                ...state,
                carregandoTarefasCliente: action.payload
            };
        case 'SALVA_TAREFAS_CLIENTE':
            return {
                ...state,
                tarefasCliente: action.payload
            };
        // Adiciona casos para adição de cliente
        case 'ADICIONANDO_CLIENTE':
            return {
                ...state,
                adicionandoCliente: action.payload
            };
        case 'CLIENTE_ADICIONADO':
            return {
                ...state,
                clienteAdicionado: action.payload
            };
        case 'DESLOGAR':
            return initialState;
        default:
            return state;
    }
};

export default MCReducer;