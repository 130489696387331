import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import apiUrl from "../../const";
import '../../index.css';

function PaginaDownload(props) {

    function extractToken() {
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        return urlParams.get('tk');
    }

    function extractName(){
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        return urlParams.get('nm') || 'Backup.zip';
    }

    function formatFileSize(size) {
        const units = ['KB','KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)} ${units[unitIndex]}`;
    }
    function formatTimeRemaining(seconds) {
        if (seconds < 60) {
            return `${Math.ceil(seconds)} segundos`;
        } else if (seconds < 3600) {
            const minutes = Math.ceil(seconds / 60);
            return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
        } else {
            const hours = Math.ceil(seconds / 3600);
            return `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
        }
    }
    const [downloadIniciado, setDownloadIniciado] = useState(false);
    const [erroNoDownload, setErroNoDownload] = useState(false);
    const [porcentagemDownload, setPorcentagemDownload] = useState(0);
    const [totalDownload, setDownloadTotal] = useState(0);
    const [totalBaixado, setTotalBaixado] = useState(0);
    const [downloadFinalizado, setDownloadFinalizado] = useState(false);
    const [velocidadeDownload, setVelocidadeDownload] = useState(0);
    
    // Replace state variables with refs for tracking time and bytes
    const lastTimeRef = useRef(Date.now());
    const lastBytesRef = useRef(0);
    // Refs to track current values
    const atualRef = useRef(0);
    const totalRef = useRef(0);
    
    // Update refs when state changes
    useEffect(() => {
        atualRef.current = totalBaixado;
        totalRef.current = totalDownload;
    }, [totalBaixado, totalDownload]);
    
    // Add interval for calculating download speed
    useEffect(() => {
        const updateTimer = setInterval(() => {
            const currentAtual = atualRef.current;
            const currentTotal = totalRef.current;
            
            if (currentAtual > 0 && currentTotal > 0) {
                const now = Date.now();
                const deltaTime = (now - lastTimeRef.current) / 1000; // em segundos
                const deltaBytes = currentAtual - lastBytesRef.current;
                
                if (deltaTime > 0) {
                    const speed = deltaBytes / deltaTime; // bytes por segundo
                    setVelocidadeDownload(speed > 0 ? speed : 0);
                }
                
                // Update values for next measurement
                lastTimeRef.current = now;
                lastBytesRef.current = currentAtual;
            }
        }, 500);
        
        return () => clearInterval(updateTimer);
    }, []);
    
    const elementoPadrao = <div className="text-center space-y-6">
        <h1 className="text-2xl font-semibold text-neutral-100">Faça download do arquivo clicando no botão abaixo</h1>
        <Button variant="success" className="mt-3 px-8 py-2 transition-all duration-300 hover:scale-105" onClick={botaoDownload}>
            {(downloadIniciado && !downloadFinalizado) ? <Spinner size="sm" /> : 'Baixar'}
        </Button>
    </div>

    const elementoCarregandoDownload = <div className="text-center space-y-4">
        <p className='text-xl font-medium text-neutral-300'>Por favor aguarde enquanto seu aquivo é carregado...</p>
        <Spinner className="h-8 w-8" />
    </div>

    const elementoErroNoDownload = <div className="text-center space-y-4">
        <h1 className="text-3xl font-bold text-red-500">Erro no download!</h1>
        <p className='text-lg text-red-400'>O link usado não é válido ou está expirado</p>
    </div>

    const elementoDownloadExecucanto = <div className="text-center space-y-6">
        <p className='text-xl font-medium text-neutral-300'>Efetuando o Download!</p>
        <div className="w-full max-w-md mx-auto mb-6">
            <ProgressBar now={porcentagemDownload} animated className='bg-neutral-700 h-5 rounded-full'/>
        </div>
        <div className="grid grid-cols-2 gap-4 max-w-md mx-auto bg-neutral-700/30 p-4 rounded-lg border border-neutral-600/50">
            <div className="col-span-2">
                <p className='text-lg text-neutral-200'>
                    Progresso: <span className="font-semibold text-white">{porcentagemDownload}%</span>
                </p>
            </div>
            <div>
                <p className='text-sm text-neutral-400'>Total Baixado</p>
                <p className="font-medium text-neutral-100">{formatFileSize(totalBaixado)}</p>
            </div>
            <div>
                <p className='text-sm text-neutral-400'>Tamanho Total</p>
                <p className="font-medium text-neutral-100">{formatFileSize(totalDownload)}</p>
            </div>
            <div>
                <p className='text-sm text-neutral-400'>Velocidade</p>
                <p className="font-medium text-neutral-100">{(velocidadeDownload / (1024 * 1024)).toFixed(2)} MB/s</p>
            </div>
            <div>
                <p className='text-sm text-neutral-400'>Tempo Restante</p>
                <p className="font-medium text-neutral-100">
                    {velocidadeDownload > 0 
                        ? formatTimeRemaining(Math.ceil((totalDownload - totalBaixado) / velocidadeDownload))
                        : 'Calculando...'}
                </p>
            </div>
        </div>
    </div>
    const elementoDownloadFinalizado = <div className="text-center space-y-6">
        <p className='text-xl font-medium text-green-400'>Download Finalizado!</p>
        <Button variant="success" className="mt-3 px-8 py-2 transition-all duration-300 hover:scale-105" onClick={botaoDownload}>
            {(downloadIniciado && !downloadFinalizado) ? <Spinner size="sm" /> : 'Baixar Novamente'}
        </Button>
    </div>

    function selecionaElemento() {
        if (erroNoDownload) {
            return elementoErroNoDownload;
        } else if (!downloadIniciado) {
            return elementoPadrao;
        } else if (totalBaixado <= 0) {
            return elementoCarregandoDownload;
        } else if (totalBaixado > 0 && porcentagemDownload <= 99) {
            return elementoDownloadExecucanto;
        } else {
            return elementoDownloadFinalizado;
        }
    }

    async function botaoDownload() {
        setDownloadIniciado(true);
        setDownloadFinalizado(false);
        setErroNoDownload(false); // Resetar erro antes de iniciar novo download
        try {
            const response = await axios.post(`${apiUrl}/downloadArquivoToken`, { token: extractToken() }, {
                responseType: 'blob', // Configura o axios para receber a resposta como um Blob
                timeout: 999999999999999,
                onDownloadProgress: (progressEvent) => {
                    // Update the refs and state values, but don't calculate speed here
                    // Speed calculation moved to the interval timer
                    
                    // Calcula o progresso em porcentagem
                    let downloadPorcentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    setPorcentagemDownload(downloadPorcentage);
                    
                    // Armazena o tamanho total e o tamanho já percorrido
                    setDownloadTotal(progressEvent.total);
                    setTotalBaixado(progressEvent.loaded);
                    
                    if (downloadPorcentage === 100) {
                        setDownloadFinalizado(true);
                    }
                }
            });
    
            // Cria uma URL temporária para o arquivo Blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            // Cria um link de download
            const a = document.createElement('a');
            a.href = url;
            a.download = extractName();
            document.body.appendChild(a);
            a.click();
            a.remove();
    
            // Libera a URL temporária
            window.URL.revokeObjectURL(url);
    
        } catch (erro) {
            setErroNoDownload(true);
            setDownloadIniciado(false);
            setDownloadFinalizado(false);
            document.title = `Erro`;
        }
    }    

    useEffect(() =>{
        document.title = `Baixando ${porcentagemDownload}%`;
        if (porcentagemDownload >= 100) document.title = `Download Finalizado`;
    }, [porcentagemDownload]);

    useEffect(() => {
        if(porcentagemDownload <= 99) {
            document.title = `UpCloud Download`;
        }
    }, []);

    return (
        <div className='bg-neutral-900 text-white min-h-screen'>
            <div className="flex flex-col items-center pt-10 px-4">
                <img src={process.env.PUBLIC_URL + '/logo.png'} className='max-w-xs transition-transform duration-300 hover:scale-105' id="pagina-download-logo" alt="Logo" />

                <div className="cont-download bg-neutral-800 shadow-lg rounded-xl border border-neutral-700 p-8 max-w-3xl w-full mt-8 backdrop-blur-sm">
                    {selecionaElemento()}
                </div>

                <footer className="mt-8 pb-6">
                    <p className="text-neutral-500">Copyright © 2024 - 
                        <a href="https://www.gt2a.com.br/" target="_blank" rel="noopener noreferrer" className="text-neutral-400 hover:text-neutral-300 transition-colors duration-300">
                            <span className="underline underline-offset-4 ml-1">GT2A Sistemas</span>
                        </a>
                    </p>
                </footer>
            </div>
        </div>
    );
}

export default PaginaDownload;
