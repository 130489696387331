import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * Component for task action buttons (new task, refresh)
 */
function TaskActions({ onNewTask, onRefresh, tema }) {
  return (
    <div className="flex">
      <Button 
        variant="success"
        size="sm" 
        className={`${tema === 'claro' ? 'text-black hover:bg-green-400' : ''} mr-2`}
        onClick={onNewTask}
      >
        <i className="bi bi-plus-circle mr-1"></i>
        Nova Tarefa
      </Button>
      <Button 
        variant="primary"
        size="sm" 
        className={`${tema === 'claro' ? 'text-black hover:bg-blue-400' : ''}`}
        onClick={onRefresh}
      >
        <i className="bi bi-arrow-clockwise mr-1"></i>
        Atualizar
      </Button>
    </div>
  );
}

TaskActions.propTypes = {
  onNewTask: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  tema: PropTypes.oneOf(['claro', 'escuro']).isRequired
};

export default TaskActions;