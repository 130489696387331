import React from "react";
import { connect } from "react-redux";
import { trocaTema } from "../../redux/actions";
import { deslogar } from "./actions";
import { useNavigate } from "react-router-dom";

function MCHeader(props) {
    const navigate = useNavigate();
    const { dadosUsuario, tema } = props;

    function desloga() {
        props.deslogar();
        navigate("/login");
    }

    return (
        <div className="bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg p-4 mb-3">
            <div className="row flex items-center justify-between">
                {/* Left side */}
                <div className="col-md-4">
                    <div className="flex flex-col">
                        <h1 className="text-2xl font-semibold mb-1">
                            Painel de Gerenciamento
                        </h1>
                        <div className="flex items-center">
                            <span className="text-sm opacity-75">Gerencie os seus clientes abaixo</span>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 hidden md:flex justify-center">
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="h-20" />
                </div>

                <div className="col-md-4 flex flex-col items-end">
                    <div className="flex items-center mb-1">
                        <h1 className="text-xl font-medium mr-4">
                            {dadosUsuario.nome}
                        </h1>
                        <div 
                            className={` rounded-full transition-all duration-200 ${tema === 'claro' ? 'hover:text-blue-600' : 'hover:text-emerald-300'}`}
                            onClick={desloga}
                        >
                            <i className="bi bi-box-arrow-right cursor-pointer"></i>
                        </div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div 
                            className={`flex items-center cursor-pointer ${tema === 'claro' ? 'hover:text-blue-600' : 'hover:text-emerald-300'} mb-2`}
                            onClick={props.abreFecha}
                        >
                            <span className="mr-1 text-xl font-medium mr-4">Configurações</span>
                            <i className="bi bi-gear-wide"></i>
                        </div>
                        <div 
                            className={`flex items-center cursor-pointer px-3 py-1 rounded-lg border transition-all ${tema === 'claro' ? 'hover:bg-neutral-200' : 'hover:bg-white hover:text-black'}`}
                            onClick={props.trocaTema}
                        >
                            <span className="mr-1 text-sm">Tema: {tema === 'claro' ? 'Claro' : 'Escuro'}</span>
                            <i className={`bi bi-${tema === 'claro' ? 'moon-stars' : 'sun-fill'} text-lg`}></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        dadosTarefas: state.appReducer.dadosTarefas,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trocaTema: () => dispatch(trocaTema()),
        deslogar: () => dispatch(deslogar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCHeader)