const initialState = {
    listaErros: [
        {
            id: 0,
            usuario_id: 0,
            tarefa_id: 0,
            ultima_execucao: "2022-10-19T03:00:00.000Z",
            dias_sem_executar: 0,
            nome_da_tarefa: "",
            nome: "",
            senha: "0",
            usuario: "0",
            contato: "0"
        },
    ],
    listaFechados: [],
    listaTodos: [],
    ADMLogado: false,
    clienteDetalhe: 0,
    loginErro: false,
    espacoDisco: {livre: 0, porcentagemUsada: 0, total: 0}
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_LISTA_ERROS':
            return {
                ...state,
                listaErros: action.payload
            }
        case 'GET_LISTA_FECHADOS':
            return {
                ...state,
                listaFechados: action.payload
            }

        case 'GET_LISTA_TODOS':
            return {
                ...state,
                listaTodos: action.payload
            }
        case 'LOGADO':
            return {
                ...state,
                ADMLogado: action.payload
            }
        case 'SELECIONA_CLIENTE_DETALHE':
            return {
                ...state,
                clienteDetalhe: action.payload
            }
        case 'STATUS_LOGIN':
            return {
                ...state,
                loginErro: action.payload
            }
        case 'GET_ESPACO_DISCO':
            return{
                ...state,
                espacoDisco: action.payload
            }

        default:
            return state;
    }
}

export default reducer
