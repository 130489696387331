import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Alerta from "./Alerta";
import TabsConfigTarefas from "./TabsConfigTarefas";
import { useDispatch } from 'react-redux';
import { atualizaTarefaConfig, ativaBackup, adicionarTarefa } from "../redux/actions";
import ModalNomeTarefa from "./ModalNomeTarefa";
import Checkbox from "./components Tarefas/Checkbox";
import FormTarefaConfig from "./FormTarefaConfig";

function ConfigModalTarefas(props) {

    const [senhaAtual, setSenhaAtual] = useState(null)
    const [novaSenha, setNovaSenha] = useState()
    const [confimacaoSenha, setConfimacaoSenha] = useState()
    const [emailResponsavel, setEmailResponsavel] = useState(props.dadosUsuario.email_responsavel)
    const [email, setEmail] = useState(props.dadosUsuario.email)
    const iconeEditar = <i className="bi bi-pencil-square hover:cursor-pointer" onClick={() => setShowModalNome(true)}></i>

    const [showAlerta, setShowAlerta] = useState(false)
    const [alertaTexto, setAlertaTexto] = useState('Salvo com sucesso')
    const [alertaStatus, setAlertaStatus] = useState('suceso')
    const [showModalNome, setShowModalNome] = useState(false)
    const [isNovoModo, setIsNovoModo] = useState(false)

    const [nomeTarefa, setNomeTarefa] = useState("")
    const [dadosTarefa, setDadosTarefa] = useState(
        {
            nome_tarefa: "",
            agendamento_dias: '',
            agendamento_hora: '',
            agendamento_tipo: '',
            backup: 1,
            backup_ativo: 1,
            compactacao_senha: '',
            compactacao_tipo: '',
            copia_diaria: '',
            copia_mensal: '',
            copia_mensal_ultima: '',
            copia_semanal: '',
            criptografa_compactacao: '',
            desliga_computador: '',
            destino: '',
            email_enviado_rest_auto: '',
            envia_email_conclusao: '',
            executa_restauracao_automatica: '',
            origem: '',
            solicita_usuario_senha: '',
            substitui_ultima_copia: '',
            tarefa_id: '',
            tipo_conexao: '',
            validacao_avancada: '',
            validacao_normal: ''
        }
    )

    const dispatch = useDispatch();

    useEffect(() => {
        // Determina se está em modo de nova tarefa ou edição
        setIsNovoModo(props.modoNovo || false);
        
        if (props.modoNovo) {
            // Inicializa o formulário vazio para nova tarefa
            setNomeTarefa("");
            setDadosTarefa({
                nome_tarefa: "",
                agendamento_dias: '',
                agendamento_hora: '',
                agendamento_tipo: '',
                backup: 1,
                backup_ativo: 1,
                compactacao_senha: '',
                compactacao_tipo: '',
                copia_diaria: '',
                copia_mensal: '',
                copia_mensal_ultima: '',
                copia_semanal: '',
                criptografa_compactacao: '',
                desliga_computador: '',
                destino: '',
                email_enviado_rest_auto: '',
                envia_email_conclusao: '',
                executa_restauracao_automatica: '',
                origem: '',
                solicita_usuario_senha: '',
                substitui_ultima_copia: '',
                tarefa_id: '',
                tipo_conexao: '',
                validacao_avancada: '',
                validacao_normal: ''
            });
        } else if (props.configTarefa.length > 0) {
            // Carrega os dados para edição de tarefa existente
            setDadosTarefa(props.configTarefa[0]);
            setNomeTarefa(props.nomeTarefa);
        }
    }, [props.nomeTarefa, props.configTarefa, props.modoNovo])

    function apagaAlerta() {
        setShowAlerta(false)
    }

    function resetaConfigurações() {
        setConfimacaoSenha(null)
        setNovaSenha(null)
        setSenhaAtual(null)
    }

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark text-white border-neutral-700' :
            'bg-light'
    )

    function salvaGeral(chave, valor) {
        setDadosTarefa(prev => ({
            ...prev,
            [chave]: valor
        }))
    }
    
    function handleSave() {
        // Validar se o campo origem está preenchido
        if (!dadosTarefa.origem) {
            alert('O caminho do arquivo ou do servidor preenchido');
            return;
        }
        
        // Validar se o nome da tarefa está preenchido
        if (!nomeTarefa) {
            alert('O nome da tarefa precisa ser preenchido');
            return;
        }

        if (isNovoModo) {
            // Para nova tarefa
            const dadosTarefaComNome = {
                ...dadosTarefa,
                nome_tarefa: nomeTarefa,
                inscricao: props.dadosUsuario.inscricao,
                tipo_backup: dadosTarefa.backup // Garantindo que o tipo_backup é enviado explicitamente
            };
            dispatch(adicionarTarefa(dadosTarefaComNome));
        } else {
            // Para atualização de tarefa existente
            dispatch(atualizaTarefaConfig(dadosTarefa));
        }
        props.abreFecha();
        resetaConfigurações();
        setAlertaTexto('Salvo com sucesso');
        setAlertaStatus('suceso');
        setShowAlerta(true);
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.abreFecha} className="modal-lg modal-config">
                <Modal.Header closeButton className={`modal-header ${backgroudClass}`}>
                    <Modal.Title>
                        {isNovoModo ? 'Nova Tarefa' : `Configurações da Tarefa`}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className={backgroudClass}>
                    {props.carregandoConfigTarefa && !isNovoModo ?
                        <div className="text-center">
                            <div className="spinner-border text-primary w-11 h-11 text-2xl" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p>Carregando...</p>
                        </div> :
                        <div>
                            <FormTarefaConfig 
                                label='Nome da tarefa' 
                                placeholder='Digite o nome da tarefa' 
                                className='mb-3' 
                                value={nomeTarefa} 
                                onChange={e => setNomeTarefa(e.target.value)} 
                            />

                            <div className="flex">
                                <Checkbox titulo='Tipo de backup' mbn value={dadosTarefa.backup} onChange={e => salvaGeral('backup', parseInt(e.target.value))} >
                                    <option value={0}>Arquivo</option>
                                    <option value={1}>Firebird</option>
                                    <option value={2}>SQL Server</option>
                                    <option value={5}>PostgreSQL</option>
                                </Checkbox>
                            </div>

                            <TabsConfigTarefas dadosTarefa={dadosTarefa} setDadosTarefa={setDadosTarefa} />
                        </div>
                    }
                </Modal.Body>
                
                <Modal.Footer className={`${backgroudClass} flex justify-between`}>
                    <div>
                        {!isNovoModo && (
                            <Button 
                                variant="primary" 
                                className={` ${props.tema === 'claro' && 'text-black hover:bg-blue-400 border-bg-green-400 hover:border-bg-green-400'}`} 
                                onClick={() => { dispatch(ativaBackup(dadosTarefa.tarefa_id)); props.abreFecha(); resetaConfigurações(); setShowAlerta(true) }}
                            >
                                Rodar Backup
                            </Button>
                        )}
                    </div>

                    <div>
                        <Button 
                            variant="primary" 
                            className={`mr-3 ${props.tema === 'claro' && 'text-black hover:bg-blue-400'}`} 
                            onClick={() => { props.abreFecha(); resetaConfigurações() }}
                        >
                            Fechar
                        </Button>
                        <Button 
                            variant="success" 
                            className={`${props.tema === 'claro' && 'text-black hover:bg-green-400 border-bg-green-400 hover:border-bg-green-400'}`} 
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Alerta texto={alertaTexto} status={alertaStatus} show={showAlerta} apagaAlerta={apagaAlerta} />
            <ModalNomeTarefa show={showModalNome} setShow={setShowModalNome} nome={nomeTarefa} setNome={setNomeTarefa} id={dadosTarefa.tarefa_id} />
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        nomeTarefa: state.appReducer.nomeTarefaSelecionada,
        configTarefa: state.appReducer.configTarefa,
        carregandoConfigTarefa: state.appReducer.carregandoConfigTarefa
    }
}

export default connect(mapStateToProps, null)(ConfigModalTarefas)

