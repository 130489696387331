import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

function Alerta(props) {

    const dispatch = useDispatch()

    function apagaAlerta(){
        dispatch({type: 'EXIBE_ALERTA', payload: {show: false, mensagem: '', status: ''}})
    }

    const timeOut = props.alertaStatus == 'sucesso' ? 4000 : 8000

    useEffect(() => {
        if(props.persistente != true){
            const timeout = setTimeout(() => apagaAlerta(), timeOut);
            return () => clearTimeout(timeout);
        }
    }, [props.showAlerta]);


    return (
        props.showAlerta && (
            <div class={`alertas opacity-90 fixed bottom-3 right-3 toast align-items-center ${props.alertaStatus === 'sucesso' ? 'text-bg-success' : 'text-bg-danger'} border-0 fade show `} role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {props.alertaMensagem}
                        <span className="absolute right-4 top-2 cursor-pointer" onClick={apagaAlerta}>
                            <i class="bi bi-x-lg text-xl"></i>
                        </span>
                    </div>
                </div>
            </div>
        )
    );

}

const mapStateToProps = (state) => {
    return {
        showAlerta: state.appReducer.showAlerta,
        alertaMensagem: state.appReducer.alertaMensagem,
        alertaStatus: state.appReducer.alertaStatus,
    }
}

export default connect(mapStateToProps, null)(Alerta)