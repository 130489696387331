import axios from "axios";
import apiUrl from "../../const";

export function getClientes(usuario_adm) {
    return async (dispatch) => {
        dispatch({ type: "CARREGANDO_TABELA_DE_CLIENTES", payload: true })
        try {
            const response = await axios.post(`${apiUrl}/getMultiplosClientes`, { usuario_adm: usuario_adm })
            dispatch({ type: "SALVA_DADOS_CLIENTES", payload: response.data.dadosClientes });
            dispatch({ type: "CARREGANDO_TABELA_DE_CLIENTES", payload: false })
        } catch (error) {
            console.log(error)
        }
    }
}


export function getHistoricoCliente(id) {
    return async (dispatch) => {
        dispatch({ type: "CARREGANDO_TABELA_HISTORICO_CLIENTE", payload: true })
        try {
            const response = await axios.post(`${apiUrl}/getHistoricoCliente`, { id: id })
            dispatch({ type: "SALVA_DADOS_HISTORICO_CLIENTE", payload: response.data.dadosHistoricoClientes });
            dispatch({ type: "CARREGANDO_TABELA_HISTORICO_CLIENTE", payload: false })
        } catch (error) {
            console.log(error)
        }
    }
}

export function atualizaHorarioDownload(horario, usuario) {
    return async () => {
        try {
            await axios.post(`${apiUrl}/atualizaHorarioDownload`, { horario: horario, usuario: usuario })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getClienteTarefas(inscricao) {
    return async (dispatch) => {
        dispatch({ type: "CARREGANDO_TAREFAS_CLIENTE", payload: true })
        try {
            const response = await axios.post(`${apiUrl}/getMcClienteTarefas`, { codigo: inscricao })
            dispatch({ type: "SALVA_TAREFAS_CLIENTE", payload: response.data.tarefas });
            dispatch({ type: "CARREGANDO_TAREFAS_CLIENTE", payload: false })
        } catch (error) {
            console.log(error)
            dispatch({ type: "CARREGANDO_TAREFAS_CLIENTE", payload: false })
        }
    }
}

export function adicionarCliente(dadosCliente) {
    return async (dispatch) => {
        dispatch({ type: "ADICIONANDO_CLIENTE", payload: true })
        try {
            const response = await axios.post(`${apiUrl}/adicionarCliente`, dadosCliente)
            if (response.data.success) {
                dispatch({ type: "CLIENTE_ADICIONADO", payload: true })
            } else {
                dispatch({ type: "CLIENTE_ADICIONADO", payload: false })
            }
            dispatch({ type: "ADICIONANDO_CLIENTE", payload: false })
            return response.data
        } catch (error) {
            console.log(error)
            dispatch({ type: "ADICIONANDO_CLIENTE", payload: false })
            dispatch({ type: "CLIENTE_ADICIONADO", payload: false })
            throw error
        }
    }
}

export function deslogar() {
    localStorage.clear();
    return {
        type: "DESLOGAR",
    };
}