import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * Reusable pagination component
 */
function Pagination({ 
    currentPage, 
    totalItems, 
    itemsPerPage, 
    onPageChange, 
    tema,
    visiblePagesCount = 3
}) {
    const [paginationElements, setPaginationElements] = useState([]);
    
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    
    useEffect(() => {
        renderPages(currentPage);
    }, [currentPage, itemsPerPage, totalItems, tema]);
    
    function renderPages(activePage) {
        const pages = [];
        const firstVisiblePage = Math.max(1, activePage - visiblePagesCount);
        const lastVisiblePage = Math.min(totalPages, activePage + visiblePagesCount);
        
        // Logic for showing a range of pages
        for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
            pages.push(
                <li key={i} className={i === activePage ? 'page-item active' : 'page-item'}>
                    <a 
                        className={
                            i === activePage 
                                ? 'page-link active' 
                                : (tema === 'escuro' 
                                    ? 'page-link bg-black text-white hover:bg-black hover:border-slate-400 cursor-pointer' 
                                    : 'page-link text-black cursor-pointer')
                        }
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </a>
                </li>
            );
        }
        
        setPaginationElements(pages);
    }
    
    // Don't render pagination if there's only one page or no pages
    if (totalPages <= 1) {
        return null;
    }
    
    return (
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-dark">
                {/* First page button */}
                <li className="page-item">
                    <a 
                        className={
                            tema === "escuro"
                                ? "page-link bg-black text-white hover:bg-black hover:border-slate-400 cursor-pointer"
                                : "page-link cursor-pointer text-black"
                        }
                        onClick={() => onPageChange(1)}
                    >
                        Primeira
                    </a>
                </li>
                
                {/* Page numbers */}
                {paginationElements}
                
                {/* Last page button */}
                <li className="page-item">
                    <a 
                        className={
                            tema === "escuro"
                                ? "page-link bg-black text-white hover:bg-black hover:border-slate-400 cursor-pointer"
                                : "page-link cursor-pointer text-black"
                        }
                        onClick={() => onPageChange(totalPages)}
                    >
                        Última
                    </a>
                </li>
            </ul>
        </nav>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    tema: PropTypes.oneOf(['claro', 'escuro']).isRequired,
    visiblePagesCount: PropTypes.number
};

export default Pagination;