import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";

function Card(props) {
    const { percentage, text } = props;
    
    // Split the text to separate storage used and total plan
    const parts = text.split(' do plano de ');
    const storageUsed = parts[0] || '';
    const totalPlan = parts[1] || '';
    
    // Determine color based on percentage used
    const getProgressColor = () => {
        if (percentage > 90) return '#ff0f35'; // Red if over 90%
        if (percentage > 70) return '#ffa500'; // Orange if over 70%
        return props.tema === 'claro' ? '#3b82f6' : "#2bffb4"; // Blue or Green for normal usage
    };
    
    // Get trail background color based on theme
    const getTrailBackground = () => {
        return props.tema === 'claro' 
            ? 'rgba(229, 231, 235, 0.4)' 
            : 'rgba(55, 65, 81, 0.4)';
    };

    return (
        <div className="">
            <div className={`w-88 h-28 shadow-lg backdrop-blur-md rounded-2xl overflow-hidden relative
                ${props.tema === 'escuro'
                    ? 'bg-dark text-white border border-gray-700/50'
                    : 'bg-white/80 text-gray-800 border border-gray-200/50'
                }`}>
                
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-16 h-16 rounded-full bg-gradient-to-br from-transparent 
                    to-opacity-10 blur-xl -mt-8 -mr-8 
                    from-blue-500/10 to-purple-500/5"></div>
                <div className="absolute bottom-0 left-0 w-12 h-12 rounded-full bg-gradient-to-tr from-transparent 
                    to-opacity-10 blur-xl -mb-6 -ml-6 
                    from-blue-500/10 to-purple-500/5"></div>
                
                <div className="flex h-full relative z-10">
                    {/* Progress circle */}
                    <div className="w-1/3 flex justify-center items-center p-3">
                        <div className="w-20 h-20 drop-shadow-md">
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                strokeWidth={8}
                                styles={buildStyles({
                                    textSize: '1.2rem',
                                    textColor: props.tema === 'claro' ? '#111827' : '#ffffff',
                                    pathColor: getProgressColor(),
                                    trailColor: getTrailBackground(),
                                    pathTransition: 'stroke-dashoffset 0.5s ease 0s',
                                    rotation: 0.25 // Start from top
                                })}
                            />
                        </div>
                    </div>
                    
                    {/* Text content */}
                    <div className="w-2/3 flex flex-col justify-center p-3">
                        <div className="font-bold text-lg mb-1 flex items-center gap-2">
                            <i className={`bi bi-hdd-stack ${props.tema === 'claro' ? 'text-blue-600' : 'text-emerald-400'}`}></i>
                            <span>Armazenamento</span>
                        </div>
                        <div className="text-sm">
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center">
                                    <span className={`font-medium ${percentage > 90 ? 'text-red-500' : percentage > 70 ? 'text-amber-500' : props.tema === 'claro' ? 'text-blue-600' : 'text-emerald-400'}`}>
                                        {storageUsed}
                                    </span>
                                </div>
                                {totalPlan && (
                                    <div className={`flex items-center gap-1 ${props.tema === 'claro' ? 'text-gray-600' : 'text-gray-300'}`}>
                                        <span>Plano:</span>
                                        <span className="font-medium">{totalPlan}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema
    }
}

export default connect(mapStateToProps, null)(Card)
