import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Button } from 'react-bootstrap';
import apiUrl from '../const';
import { connect } from 'react-redux';

// Função para formatar o tamanho dos arquivos
const formatFileSize = (size) => {
    if (size === undefined || size === null || size < 5000) {
        return '0 KB';
    }
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
};

// Função para formatar o tamanho dos arquivos do servidor
const formatFileSizeServer = (size) => {
    if (size === undefined || size === null) {
        return '0 KB';
    }
    size = size * 1000;
    if (size < 5000) {
        return '0 KB';
    }
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
};

// Função para formatar datas
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

const EspacoServidor = (props) => {
    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar o carregamento
    const [activeFolder, setActiveFolder] = useState(null); // Estado para controlar qual pasta está aberta

    // Temas para estilos
    const tableTemaEscuro = 'table-dark table-striped table-bordered table-hover';
    const theadTemaEscuro = '';
    const tbodyTemaEscuro = '';

    const tableTemaClaro = 'table-light table-striped table-bordered table-hover';
    const theadTemaClaro = '';
    const tbodyTemaClaro = '';

    // Função para deletar arquivos
    const handleDelete = async (folderName, fileName) => {
        try {
            await axios.post(`${apiUrl}/AdmDeleteFile`, {
                folder: folderName,
                file: fileName
            });
            // Atualizar a lista de pastas após a exclusão
            setFolders(prevFolders => {
                return prevFolders.map(folder => {
                    if (folder.name === folderName) {
                        return {
                            ...folder,
                            contents: folder.contents.filter(file => file.name !== fileName)
                        };
                    }
                    return folder;
                });
            });
            alert('Arquivo excluído com sucesso!');
        } catch (error) {
            console.error('Erro ao excluir o arquivo:', error);
            alert('Erro ao excluir o arquivo.');
        }
    };

    // Função para enviar arquivos para o Google Drive
    const handleEnviar = async (folderName, fileName) => {
        try {
            // Enviar a requisição para o servidor
            const response = await axios.post(`${apiUrl}/uploadToDrive`, {
                folder: folderName,
                file: fileName
            });

            if (response.status === 200 && response.data.success) {
                alert('Arquivo enviado com sucesso para o Google Drive!');
            } else {
                alert('Falha ao enviar o arquivo para o Google Drive.');
            }
        } catch (error) {
            console.error('Erro ao enviar o arquivo:', error);
            alert('Erro ao enviar o arquivo.');
        }
    };

    // Função para buscar dados das pastas
    const fetchData = async () => {
        try {
            setIsLoading(true); // Iniciar carregamento
            const response = await axios.get(`${apiUrl}/pastasServidor`, {
                timeout: 99999999999999
            });
            setFolders(response.data); // Dados já incluem verificação do Google Drive
            setIsLoading(false); // Finalizar carregamento
        } catch (error) {
            console.error('Erro ao buscar dados das pastas:', error);
            alert('Erro ao buscar dados das pastas. Verifique o console para mais detalhes.');
            setIsLoading(false); // Finalizar carregamento mesmo em caso de erro
        }
    };

    // Função para alternar a pasta ativa
    const toggleFolder = (index) => {
        setActiveFolder(prevIndex => (prevIndex === index ? null : index));
    };

    // Renderização condicional baseada no estado de carregamento e dados
    if (isLoading) {
        // Tela de carregamento
        return (
            <Container className="text-center my-5 border p-5 rounded-lg">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </Spinner>
                <p className="mt-3">Carregando dados, por favor aguarde...</p>
            </Container>
        );
    }

    if (folders.length === 0) {
        // Mostrar botão de carregamento inicial se não há pastas
        return (
            <Container className="text-center my-5 border p-5 rounded-lg">
                <Button
                    className={props.tema === 'escuro' ? '' : 'text-black'}
                    onClick={fetchData}
                    variant="primary"
                >
                    Carregar Arquivos
                </Button>
            </Container>
        );
    }

    return (
        <Container>
            {folders.map((folder, index) => (
                <div
                    key={index}
                    className={` border ${
                        props.tema === 'escuro' ? 'bg-dark text-white' : 'bg-white text-black'
                    } `}
                >
                    {/* Cabeçalho da Pasta */}
                    <button
                        onClick={() => toggleFolder(index)}
                        className={`w-full text-left px-4 py-2 focus:outline-none ${
                            props.tema === 'escuro'
                                ? 'bg-dark text-white hover:bg-slate-700'
                                : 'bg-neutral-100 text-black hover:bg-neutral-200'
                        }`}
                    >
                        <span className="font-semibold">
                            {folder.name} - {formatFileSize(folder.size)}
                        </span>
                        <span className="float-right">
                            {activeFolder === index ? '-' : '+'}
                        </span>
                    </button>

                    {/* Corpo da Pasta */}
                    {activeFolder === index && (
                        <div className="px-4 py-2">
                            {folder.type === 'folder' && folder.contents && folder.contents.length > 0 ? (
                                <Table
                                    bordered
                                    className={`${
                                        props.tema === 'claro' ? tableTemaClaro : tableTemaEscuro
                                    } text-center`}
                                >
                                    <thead
                                        className={
                                            props.tema === 'claro' ? theadTemaClaro : theadTemaEscuro
                                        }
                                    >
                                        <tr>
                                            <th>Nome do Arquivo</th>
                                            <th>Status</th>
                                            <th>Tamanho (Server)</th>
                                            <th>Tamanho (DB)</th>
                                            <th>Última Modificação</th>
                                            <th>No Google Drive</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className={
                                            props.tema === 'claro' ? tbodyTemaClaro : tbodyTemaEscuro
                                        }
                                    >
                                        {folder.contents.map((file, fileIndex) => (
                                            <tr key={fileIndex}>
                                                <td>{file.name}</td>
                                                <td>{file.status}</td>
                                                <td>{formatFileSize(file.size)}</td>
                                                <td>{formatFileSizeServer(file.dbSize)}</td>
                                                <td>{formatDate(file.lastModified)}</td>
                                                <td>{file.inDrive}</td>
                                                <td>
                                                    <Button
                                                        className={
                                                            props.tema === 'escuro' ? '' : 'text-black'
                                                        }
                                                        variant="danger"
                                                        onClick={() =>
                                                            handleDelete(folder.name, file.name)
                                                        }
                                                    >
                                                        Excluir
                                                    </Button>
                                                    {/* <Button
                                                        className={`ml-2 ${
                                                            props.tema === 'escuro' ? '' : 'text-black'
                                                        }`}
                                                        variant="success"
                                                        onClick={() =>
                                                            handleEnviar(folder.name, file.name)
                                                        }
                                                    >
                                                        Enviar
                                                    </Button> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p
                                    className={
                                        props.tema === 'escuro' ? 'text-white' : 'text-black'
                                    }
                                >
                                    Esta pasta está vazia ou é um arquivo.
                                </p>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </Container>
    );
};

// Mapeamento do estado do Redux para as props do componente
const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
    };
};

// Exportação do componente com React.memo para otimização
export default connect(mapStateToProps, null)(memo(EspacoServidor));
