import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from "axios";
import apiUrl from '../const';

axios.defaults.timeout = 500000;

function ModalAtualizarLista(props) {

    const btnClass = props.tema === 'claro' ? 'text-black' : ''

    const [textBtnErros, setTextBtnErros] = useState('Atualizar')
    const [textMsmErros, setTextMsmErros] = useState('')

    const [textBtnFechados, setTextBtnFechados] = useState('Atualizar')
    const [textMsmFechados, setTextMsmFechados] = useState('')

    async function atualizaListaDeErros() {
        // Atualiza o texto do botão para "Atualizando"
        setTextBtnErros("Atualizando");
        setTextMsmErros
            (<div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>)

        // Endpoint para a requisição
        const endpoint = `${apiUrl}/verificaExecucaoErro`

        try {
            // Faz a requisição ao endpoint
            const response = await axios.post(endpoint);

            if (response.status >= 200 && response.status < 300) {
                setTextMsmErros(<span className='text-green-600'>Atualizado com sucesso</span>)
                setTextBtnErros("Atualizar");
            } else {
                setTextBtnErros("Atualizar");
                setTextBtnErros("Erro");
            }
        } catch (error) {
            console.error("Erro na requisição:", error);
            setTextMsmErros(<span className='text-green-600'>Atualizado com sucesso</span>);
            setTextBtnErros("Atualizar");
        }
    }

    async function atualizaListaDeFechados() {
        // Atualiza o texto do botão para "Atualizando"
        setTextBtnFechados("Atualizando");
        setTextMsmFechados
            (<div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>)

        const endpoint = `${apiUrl}/verificaExecucaoFechado`

        try {
            const response = await axios.post(endpoint);

            setTextMsmFechados(
                <span className='text-green-600'>Atualizado com sucesso</span>)
            setTextBtnFechados("Atualizar");

        } catch (error) {
            console.error("Erro na requisição:", error);
            setTextBtnFechados("Atualizar")
            setTextMsmFechados(<span className='text-green-600'>Atualizado com sucesso</span>);
        }
    }


    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark text-white' :
            'bg-light'
    )

    return (
        <Modal show={props.show} onHide={props.handleShow}>
            <Modal.Header closeButton className={backgroudClass}>
                <Modal.Title>Atualizar Lista De Erros</Modal.Title>
            </Modal.Header>
            <Modal.Body className={backgroudClass}>

                <div>
                    <p>Atualizar Tabela De Erros</p>
                    {
                        textBtnErros === 'Atualizando' ?

                            <p className='mt-2'>{textMsmErros}</p> :
                            <>
                                <Button className={`mt-2 ${btnClass}`} onClick={atualizaListaDeErros} >{textBtnErros}</Button>
                                <p className='mt-2'>{textMsmErros}</p>
                            </>
                    }
                </div>

                <div className='mt-3'>
                    <p>Atualizar Tabela De Fechados</p>
                    {
                        textBtnFechados === 'Atualizando' ?

                            <p className='mt-2'>{textMsmFechados}</p> :
                            <>
                                <Button className={`mt-2 ${btnClass}`} onClick={atualizaListaDeFechados} >{textBtnFechados}</Button>
                                <p className='mt-2'>{textMsmFechados}</p>
                            </>
                    }

                </div>

            </Modal.Body>
            <Modal.Footer className={backgroudClass}>
                <Button variant="secondary" onClick={props.handleShow} className={`${btnClass}`}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema
    }
}

export default connect(mapStateToProps, null)(ModalAtualizarLista)