import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import Login from "./components/Login";
import { connect } from "react-redux";
import PainelADM from "./painelADM/PainelADM";
import PainelMultiplosClientes from "./components/painelMultiplosClientes/PainelMultiplosClientes";
import PaginaDownload from "./components/paginaDownload/paginaDownload";

function MyRoutes(props) {

    return (
        <Routes>
            <Route path="/painel-multiplos-clientes" element={<PainelMultiplosClientes />} />
            <Route path="/painel-adm" element={<PainelADM />} />
            <Route path="/painel" element={<App />} />
            <Route path="/painel-cliente" element={<App />} />
            <Route path="/download" element={<PaginaDownload />} />
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}

const mapStateToProps = (state) => {
    return {
        logado: state.logado
    }
}


export default connect(mapStateToProps, null)(MyRoutes)