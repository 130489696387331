import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import Card from "../template/Card";
import { getEspacoDisco, getListaDeErros, getListaFechados, getListaTodos } from "./actions";
import LoginADM from "./LoginADM";
import TableErros from "./TableErros";
import TableFechados from "./TableFechados";
import TableListaTodos from "./TableListaTodos";
import { trocaTema } from "../redux/actions";
import ModalDetalhes from "./ModalDetalhes";
import Alerta from "../components/Alerta";
import EspacoServidor from "./EspacoServidor";

function PainelADM(props) {

    const dispatch = useDispatch()
    const [showDetalheModal, setShowDetalheModal] = useState(false)

    function formatFileSize(size) {
        const units = ['KB', 'KB', 'MB', 'GB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)} ${units[unitIndex]}`;
    }

    useEffect(() => {
        dispatch(getListaDeErros())
        dispatch(getListaFechados())
        dispatch(getListaTodos())
        dispatch(getEspacoDisco())


        document.title = 'Administrador'

    }, [])

    useEffect(() =>{
        if(props.espacoDisco.porcentagemUsada >= 75){
            dispatch({type: "EXIBE_ALERTA", payload: {show: true, mensagem: "Epaço em disco cheio!", status: 'falha'}})
        }
    }, [props.espacoDisco])

    const appBackgroundClass = props.tema === 'claro' ? 'bg-white min-h-screen app-div text-black' : 'bg-black min-h-screen app-div text-white'

    return (
        <>

            {props.ADMLogado ?

                <div className={appBackgroundClass}>
                    <div className="container">
                        <div className="row pt-3">
                            <div className="col flex justify-center">
                                <div>
                                    <h1 className="text-4xl mb-2">Painel De Controle ADM</h1>
                                    <h2 className="text-2xl">{formatFileSize(props.espacoDisco.livre)} Livres de {formatFileSize(props.espacoDisco.total)}</h2>
                                    <h1 className="text-2xl"> Tema <span onClick={() => dispatch(trocaTema())} className='cursor-pointer'> <i className={`bi bi-${props.tema === 'claro' ? 'moon-stars' : 'sun-fill'}`}></i></span> </h1>
                                </div>
                            </div>
                            <div className="col flex justify-center">
                                <Card percentage={Math.ceil(props.espacoDisco.porcentagemUsada)} text={'Espaço Total Utilizado Em Nuvem'} />
                            </div>
                            <Alerta />
                        </div>

                        <div className="row text-center">
                            <h1 className="text-3xl mt-5 ">Lista De Clientes</h1>
                        </div>

                        <div className="row">
                            <Tabs className="nav-pills mt-4 ml-4 mb-3 text-2xl w-10/12">
                                <Tab eventKey="erros" title="Erros">
                                    <TableErros dados={props.listaErros} />
                                </Tab>

                                <Tab eventKey="fechados" title="Fechados">
                                    <TableFechados dados={props.listaFechados} />
                                </Tab>

                                <Tab eventKey="todos" title="Todos">
                                    <TableListaTodos setModal={setShowDetalheModal} showModal={showDetalheModal} dados={props.listaTodos} />
                                </Tab>

                                <Tab eventKey="espaco" title="Armazenamento Servidor">
                                    <EspacoServidor />
                                </Tab>


                            </Tabs>
                        </div>
                        <ModalDetalhes show={showDetalheModal} setShow={setShowDetalheModal} />
                    </div>
                </div> :

                <LoginADM />
            }

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        listaErros: state.admReducers.listaErros,
        listaFechados: state.admReducers.listaFechados,
        listaTodos: state.admReducers.listaTodos,
        ADMLogado: state.admReducers.ADMLogado,
        espacoDisco: state.admReducers.espacoDisco,
        tema: state.appReducer.tema
    }
}

export default connect(mapStateToProps, null)(PainelADM)