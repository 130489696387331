import React, { useState, useEffect } from "react";
import Table from "./Table";
import LoadingTableRow from "./LoadingTableRow";
import PropTypes from "prop-types";
import TableContent from "./TableContent";

/**
 * Component that displays the list of backup tasks
 */
function TaskList({ tasks, isLoading, tipo = 'tarefas' }) {
  const [sortedTasks, setSortedTasks] = useState([]);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      setSortedTasks([...tasks]);
    } else {
      setSortedTasks([]);
    }
  }, [tasks]);

  const handleSort = (column, direction) => {
    if (!tasks || tasks.length === 0) return;
    
    const sorted = [...sortedTasks].sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];
      
      // Lidar com tipos de dados específicos
      if (column === 'tamanho' || column === 'tempo_total') {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
      } else if (column === 'execucao') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }
      
      // Ordenação
      if (direction === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
    
    setSortedTasks(sorted);
  };

  if (isLoading) {
    return (
      <Table tipo="listaBackups">
        <LoadingTableRow />
      </Table>
    );
  }

  return (
    <Table 
      tipo="listaBackups"
      onSort={handleSort}
    >
      {sortedTasks && sortedTasks.length > 0 ? (
        sortedTasks.map((item, index) => (
          <TableContent 
            key={item.id || index}
            dados={item} 
            index={index} 
            tipo={tipo} 
          />
        ))
      ) : (
        <tr>
          <td colSpan={7}>
            <p className="m-2">Nenhuma tarefa disponível</p>
          </td>
        </tr>
      )}
    </Table>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  tipo: PropTypes.string
};

export default TaskList;