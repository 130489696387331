import { MD5 } from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner, Form, Alert } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { enviaEmail } from '../redux/actions';
import { geraChave } from './utilitarios/senhaArquivo';

function ModalEnvioEmail(props) {
    const dispatch = useDispatch();
    const [senhaAtual, setSenhaAtual] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [sucessoMessage, setSucessoMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark border-neutral-700 text-white' :
            'bg-light'
    );

    const buttonTextClass = props.tema === 'claro' ? 'text-black' : '';

    function validarEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    function enviar() {
        if (!senhaAtual || (MD5(senhaAtual).toString().toUpperCase() !== props.dadosUsuario.senha && senhaAtual !== props.dadosUsuario.senha)) {
            setErrorMessage('A senha está incorreta');
            return false;        
        }
        if(email == ''){
            setErrorMessage('Insira um email válido');
            return false;
        }

        const senha = props.senhaArquivo == '' || props.senhaArquivo == null ? geraChave(props.inscricao) : props.senhaArquivo

        dispatch(enviaEmail(props.dadosUsuario.inscricao, props.nomeDoArquivo, email, senha))

        limpaFormulario()
    }

    function limpaFormulario() {
        setEmail('')
        setSenhaAtual('')
        setErrorMessage('')
    }

    useEffect(() =>{
        if(props.envioStatus != '') {
            if(props.envioStatus == 'erro'){
                setErrorMessage('Erro ao enviar o email, chame o suporte')
            }
            if(props.envioStatus == 'sucesso'){
                setSucessoMessage('Email enviado com sucesso!')
            }
        }

        if(props.envioStatus == '') {
            setErrorMessage('')
            setSucessoMessage('')
        }
    }, [props.envioStatus])

    return (
        <Modal show={props.show} onHide={() => { props.setShow(false); limpaFormulario() }} >
            <Modal.Header closeButton className={backgroudClass}>
                <Modal.Title><i className="bi bi-envelope-paper me-2"></i>Enviar Backup Por Email</Modal.Title>
            </Modal.Header>
            <Modal.Body className={backgroudClass}>

                <FormModal label='Senha Atual' className='mb-2' value={senhaAtual} onChange={e => setSenhaAtual(e.target.value)} type='password' />
                <FormModal label='Email Para o Envio do Backup' className='mb-2' value={email} onChange={e => setEmail(e.target.value)} type='email' />
                <p className="text-red-400 mb-2">{errorMessage}</p>
                <p className="text-green-500 mb-2">{sucessoMessage}</p>

            </Modal.Body>
            <Modal.Footer className={backgroudClass}>
                <Button variant="primary" onClick={() => { props.setShow(false); limpaFormulario() }} className={props.tema === 'claro' ? 'text-black' : ''}>
                    Fechar
                </Button>
                {props.enviando ?
                    <Button variant="success" className={props.tema === 'claro' ? 'text-black' : ''}>
                        <Spinner size="sm"  className='mx-3'/>
                    </Button> :

                    <Button variant="success" onClick={enviar} className={props.tema === 'claro' ? 'text-black' : ''}>
                        Enviar
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );  

}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        enviando: state.appReducer.enviandoEmailBackup,
        envioStatus: state.appReducer.statusEnvioEmail,
        inscricao: state.appReducer.dadosUsuario.inscricao,
        senhaArquivo: state.appReducer.senhaArquivo
    }
}

export default connect(mapStateToProps, null)(ModalEnvioEmail)