import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { deslogar, trocaTema } from "../../redux/actions";
import MCHeader from "./MCHeader";
import MCTabelaClientes from "./MCTabelaClientes";
import MCTabelaHistorico from "./MCTabelaHistorico";
import { useNavigate } from "react-router-dom";
import MCConfiguracoes from "./MCConfiguracoes";
import MCFooter from "./MCFooter";
// No need to import MCClienteTarefasModal here as it's used within MCTabelaClientes

function PainelMultiplosClientes(props) {

    const BackgroundClass = props.tema === 'claro' ? 'bg-white min-h-screen app-div text-black' : 'bg-black min-h-screen app-div text-white'
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tarefaSelecionada, setTarefaSelecionada] = useState(false)
    const [showConfig, setShowConfig] = useState(false)
    document.title = 'Painel Gerenciador';

    function abreFecha() {
        setShowConfig(!showConfig)
    }

    useEffect(() => {
        if (!props.logado) {
            navigate('/login')
        } else if (props.dadosUsuario.tipo !== '1') {
            dispatch(deslogar())
        }
    }, [])

    return (
        <div className={`${BackgroundClass}`} >

            <div className="tabelas-mc py-3 mt">

                <MCHeader abreFecha={abreFecha} />
                <MCConfiguracoes show={showConfig} abreFecha={abreFecha} />

                <div className="">
                    <div className="">
                        <MCTabelaClientes setTarefaSelecionada={setTarefaSelecionada} />
                    </div>
                </div>

                <MCFooter />

            </div>

        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        dadosTarefas: state.appReducer.dadosTarefas,
        logado: state.appReducer.logado,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trocaTema: () => dispatch(trocaTema()),
        deslogar: () => dispatch(deslogar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PainelMultiplosClientes)