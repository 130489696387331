import { MD5 } from 'crypto-js';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { deleteArquivo } from '../redux/actions';


function ModalApagarArquivo(props) {

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark text-white border-neutral-700' :
            'bg-light'
    )

    const dispatch = useDispatch()

    function apagaArquivo(){
        props.handleShow(false)
        
        const result = dispatch(deleteArquivo(
                                props.inscricao, 
                                props.nomeDoArquivo, 
                                props.tamanhoArquivo, 
                                props.idHistorico, 
                                props.tarefaID, 
                                props.numeroLinhas,
                                props.pagina))
    }

    return (
        <Modal show={props.show} onHide={() => { props.handleShow(false)}} >
            <Modal.Header closeButton className={`${backgroudClass}`}>
                <Modal.Title>Apagar Arquivo</Modal.Title>
            </Modal.Header>
            <Modal.Body className={backgroudClass}>

                <h1 className='text-xl'>Essa função vai deletar o arquivo e o histórico selecionado, deseja continuar?</h1>

            </Modal.Body>
            <Modal.Footer className={`${backgroudClass}`}>
                <Button variant="primary" onClick={() => { props.handleShow(false) }} className={props.tema === 'claro' ? 'text-black' : ''}>
                    Cancelar
                </Button>
                <Button variant="warning" onClick={ apagaArquivo } className={props.tema === 'claro' ? 'text-black' : 'text-white'}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario,
        inscricao: state.appReducer.dadosUsuario.inscricao,
        tarefaID: state.appReducer.tarefaID,
        numeroLinhas: state.appReducer.numeroLinhas,
        pagina: state.appReducer.pagina
    }
}

export default connect(mapStateToProps, null)(ModalApagarArquivo)