import { MD5 } from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { alterarNomeTarefa, atulizaTarefa } from '../redux/actions';


function ModalNomeTarefa(props) {

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark text-white' :
            'bg-light'
    )

    function atulizaDadosTarefas() {
        props.atulizaTarefa([props.dadosUsuario.usuario, props.dadosUsuario.senha])
    };

    const [nome, setNome] = useState(props.nome)

    useEffect(() => {
        setNome(props.nome)
    }, [props.nome])

    return (
        <div className={`modal-confirmacao-fundo-preto ${props.show === false ? 'diplay-none' : ''}`}>
            <Modal show={props.show} onHide={() => { props.setShow(false) }} className='modal-confirmacao'>
                <Modal.Header closeButton className={`${backgroudClass}`}>
                    <Modal.Title>Alterar Nome da Tarefa</Modal.Title>
                </Modal.Header>
                <Modal.Body className={backgroudClass}>

                    <FormModal label='Nome' className='mb-2' value={nome} type='email' onChange={(e) => setNome(e.target.value)} />

                </Modal.Body>
                <Modal.Footer className={`${backgroudClass}`}>
                    <Button variant="primary" onClick={() => { props.setShow(false); }} className={props.tema === 'claro' ? 'text-black' : ''}>
                        Cancelar
                    </Button>
                    <Button variant="success" className={props.tema === 'claro' ? 'text-black' : ''} onClick={() => { props.setNome(nome); alterarNomeTarefa(nome, props.id); atulizaDadosTarefas(); props.setShow(false) }}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        dadosUsuario: state.appReducer.dadosUsuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        atulizaTarefa: (data) => dispatch(atulizaTarefa(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNomeTarefa)