import React, { useState } from "react";
import Card from "../template/Card";
import { connect } from "react-redux";
import { deslogar, trocaTema } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import ModalCarregando from "./ModalCarregando";
import ModalDownload from "./ModalDownload";


function Header(props) {
    const navigate = useNavigate();
    const { dadosUsuario, tema } = props;

    function desloga() {
        props.deslogar()
        navigate("/login")
    }

    return (
        <div className={`bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg py-2 px-3 mb-4 transition-all duration-300`}>
            <ModalCarregando />
            <ModalDownload />

            <div className="row flex flex-wrap items-center justify-between">
                {/* Left side */}
                <div className="col-md-4">
                    <div className="flex flex-col">
                        <h1 className={`text-2xl font-bold mb-1 transition-colors duration-300 ${tema === 'claro' ? 'text-gray-800' : 'text-white'}`}>
                            Painel Do Usuário
                        </h1>
                        <div className="flex items-center">
                            <span className="text-sm opacity-75 flex items-center">
                                <i className="bi bi-person-check mr-2"></i>
                                Bem-vindo ao seu painel de controle
                            </span>
                        </div>
                    </div>
                </div>

                {/* Center */}
                <div className="col-md-4 flex justify-center my-3 md:my-0">
                    <div className={` ${tema === 'claro' ? 'hover:shadow-md' : 'hover:shadow-gray-700'} rounded-lg`}>
                        {props.tamanho_usado > 0 ? (
                            <Card 
                                text={`${formatFileSizeDrive(props.tamanho_usado)} Usados do plano de ${formatPlano(props.dadosUsuario.plano)} GB`} 
                                percentage={calcularPorcentagemUsada(props.tamanho_usado, formatPlano(props.dadosUsuario.plano))} 
                            />
                        ) : (
                            <Card text="0 KB Usados do plano de 0 GB" percentage={0} />
                        )}
                    </div>
                </div>

                {/* Right side */}
                <div className="col-md-4 flex flex-col items-end">
                    <div className="flex items-center text-right">
                        <h1 className={`text-xl font-medium mr-1 transition-colors duration-300 ${tema === 'claro' ? 'text-gray-700' : 'text-gray-100'}`}>
                            <i className="bi bi-person-circle mr-2"></i>
                            {dadosUsuario.nome}
                        </h1>
                        <div 
                            className={`p-2 rounded-full transition-all duration-200 ${tema === 'claro' ? 'hover:bg-red-100 hover:text-red-600' : 'hover:bg-red-900 hover:text-red-300'}`}
                            onClick={desloga}
                        >
                            <i className="bi bi-box-arrow-right cursor-pointer"></i>
                        </div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div 
                            className={`flex items-center cursor-pointer p-2 rounded-lg transition-all duration-200 ${tema === 'claro' ? 'hover:bg-blue-100 hover:text-blue-600' : 'hover:bg-blue-900 hover:text-blue-300'} mb-2`}
                            onClick={props.abreFecha}
                        >
                            <span className="mr-2 font-medium">Configurações</span>
                            <i className="bi bi-gear-wide"></i>
                        </div>
                        <div 
                            className={`flex items-center cursor-pointer p-2 rounded-lg transition-all duration-300 ${
                                tema === 'claro' 
                                ? 'border-gray-200 hover:bg-gray-100 hover:border-gray-300' 
                                : 'border-gray-700 hover:bg-gray-700 hover:border-gray-600'
                            }`}
                            onClick={props.trocaTema}
                        >
                            <span className="mr-2 font-medium">Tema: {tema === 'claro' ? 'Claro' : 'Escuro'}</span>
                            <i className={`bi bi-${tema === 'claro' ? 'moon-stars' : 'sun-fill'} text-lg ${tema === 'claro' ? 'text-indigo-500' : 'text-yellow-400'}`}></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function formatFileSizeDrive(size) {
    if(size === null || size === undefined || size === 0){
        return '0 KB'
    }

    const units = ['KB','KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
}

function calcularPorcentagemUsada(espacoUsadoBytes, planoGigas) {
    // Converter o plano de armazenamento de gigabytes para bytes
    const planoBytes = planoGigas * 1024 * 1024 * 1024;

    // Calcular a porcentagem usada
    const porcentagemUsada = (espacoUsadoBytes / planoBytes) * 100;
    
    return Math.floor(porcentagemUsada);
}

function formatPlano(plano) {
    const options = [
      { value: "2", plano: 5 },
      { value: "3", plano: 10 },
      { value: "4", plano: 25 },
      { value: "5", plano: 50 },
      { value: "6", plano: 10 },
      { value: "7", plano: 25 },
      { value: "8", plano: 50 },
      { value: "9", plano: 100 },
      { value: "12", plano: 250 },
      { value: "13", plano: 500 },
      { value: "14", plano: 1000 },
      { value: "15", plano: 3000 },
      { value: "16", plano: 5000 }
    ];
    
    const option = options.find(option => option.value === plano);
    return option ? option.plano : null;
}

function calcularPorcentagem(valor, total) {
    const porcentagem = (valor / total) * 100;
    return porcentagem.toFixed(2);
}

const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        tamanho_usado: state.appReducer.tamanho_usado,
        dadosUsuario: state.appReducer.dadosUsuario,
        dadosTarefas: state.appReducer.dadosTarefas,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trocaTema: () => dispatch(trocaTema()),
        deslogar: () => dispatch(deslogar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)