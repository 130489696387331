import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { adicionarCliente } from "./actions";
import apiUrl from "../../const";

const MCAdicionarClienteModal = ({ show, handleClose, usuario_adm, tema }) => {
    // Estado inicial do formulário
    const initialFormData = {
        inscricao: "",
        nome: "",
        usuario: "",
        senha: "",
        email: "",
        estado_id: 1, // Valor fixo
        cidade_id: 1, // Valor fixo
        tipo: 2, // Valor fixo
        ultimo_acesso: formatCurrentDate(), // Data atual formatada
        versao: "1.01.47", // Valor fixo
        email_responsavel: "",
        status: "ATIVO", // Valor fixo
        telefone: "",
        contato: "",
        whatsapp: "",
        observacao: "",
        usuario_adm: usuario_adm, // ID do usuário logado
        dados_contratada: "",
        plano_contratado: "",
        contrato_assinado: "0",
        horario_download: "00:00:00", // Valor fixo
        reinicia_upcloud: "N" // Valor fixo
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    // Função para formatar a data atual como "YYYY-MM-DD HH:MM:SS"
    function formatCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // Manipulador para alterações nos campos do formulário
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        // Para checkboxes, usamos o valor 'checked' (0 ou 1)
        const fieldValue = type === 'checkbox' ? (checked ? "1" : "0") : value;
        
        setFormData({
            ...formData,
            [name]: fieldValue
        });
        
        // Limpar erro ao editar o campo
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ""
            });
        }
    };

    // Validação do formulário
    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.inscricao.trim()) newErrors.inscricao = "Campo obrigatório";
        if (!formData.nome.trim()) newErrors.nome = "Campo obrigatório";
        if (!formData.usuario.trim()) newErrors.usuario = "Campo obrigatório";
        if (!formData.senha.trim()) newErrors.senha = "Campo obrigatório";
        if (!formData.email.trim()) newErrors.email = "Campo obrigatório";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email inválido";
        
        return newErrors;
    };

    // Manipulador para envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validar formulário
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        
        setIsLoading(true);
        
        try {
            // Enviar dados para a API usando a action
            const response = await dispatch(adicionarCliente(formData));
            
            if (response.success) {
                alert("Cliente adicionado com sucesso!");
                handleClose();
                // Resetar formulário
                setFormData(initialFormData);
            } else {
                alert(`Erro ao adicionar cliente: ${response.message || "Erro desconhecido"}`);
            }
        } catch (error) {
            console.error("Erro ao adicionar cliente:", error);
            alert(`Erro ao adicionar cliente: ${error.response?.data?.message || error.message || "Erro desconhecido"}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal 
            show={show} 
            onHide={handleClose}
            size="lg"
            backdrop="static"
            centered
            className={tema === 'claro' ? '' : 'dark-modal'}
        >
            <Modal.Header closeButton className={tema === 'claro' ? 'bg-light' : 'bg-dark text-light'}>
                <Modal.Title>
                    <i className="bi bi-person-plus-fill me-2"></i>
                    Adicionar Novo Cliente
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={tema === 'claro' ? 'bg-white' : 'bg-dark text-light'}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Inscrição/CNPJ <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="inscricao"
                                    value={formData.inscricao}
                                    onChange={handleChange}
                                    isInvalid={!!errors.inscricao}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.inscricao}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    isInvalid={!!errors.nome}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.nome}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Usuário <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="usuario"
                                    value={formData.usuario}
                                    onChange={handleChange}
                                    isInvalid={!!errors.usuario}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.usuario}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Senha <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="senha"
                                    value={formData.senha}
                                    onChange={handleChange}
                                    isInvalid={!!errors.senha}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.senha}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email do Responsável</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email_responsavel"
                                    value={formData.email_responsavel}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>WhatsApp</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="whatsapp"
                                    value={formData.whatsapp}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nome do Contato</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contato"
                                    value={formData.contato}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Dados da Contratada</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="dados_contratada"
                                    value={formData.dados_contratada}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Plano Contratado</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="plano_contratado"
                                    value={formData.plano_contratado}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3 mt-4">
                                <Form.Check
                                    type="checkbox"
                                    label="Contrato Assinado"
                                    name="contrato_assinado"
                                    checked={formData.contrato_assinado === "1"}
                                    onChange={handleChange}
                                    className={tema === 'claro' ? '' : 'text-light'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Observações</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="observacao"
                            value={formData.observacao}
                            onChange={handleChange}
                            className={tema === 'claro' ? '' : 'bg-secondary text-white'}
                        />
                    </Form.Group>

                    {/* Campos ocultos com valores fixos não são exibidos no formulário */}
                </Form>
            </Modal.Body>
            <Modal.Footer className={tema === 'claro' ? 'bg-light' : 'bg-dark text-light'}>
                <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
                    Cancelar
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="d-flex align-items-center"
                >
                    {isLoading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Salvando...
                        </>
                    ) : (
                        <>
                            <i className="bi bi-save me-2"></i>
                            Salvar Cliente
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MCAdicionarClienteModal;