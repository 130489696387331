import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ModalConfimacao from '../ModalConfimacao';


function MCFooter(props) {
    const validaTema = props.tema === 'escuro'
    const [showModal, setShowModal] = useState(false)
    const [textoModal, setTextoModal] = useState('teste')
    const [textoTitulo, setTextoTitulo] = useState('teste')

    const textoApagar = `Essa função vai ler as pastas e apagar todos os arquivos com data anterior ao dia de hoje, você tem certeza que deseja realizar essa ação?`
    const tituloApagar = `Apagar Arquivos`

    const textoBaixar = `Você deseja baixar todos os arquivos?`
    const tituloBaixar = `Baixar Arquivos`

    return (
        <div>
            <div className="flex items-center justify-between mt-3 ">
                {/* <div>
                    <p>Aguardando horario de Download</p>
                </div>
                <div>
                    <Button variant="primary" onClick={() => { setShowModal(true); setTextoModal(textoApagar); setTextoTitulo(tituloApagar) }} className={`${validaTema ? 'bg-blue-800' : 'bg-blue-500'} mr-3`}>Apagar Arquivos</Button>

                    <Button variant="primary" onClick={() => { setShowModal(true); setTextoModal(textoBaixar); setTextoTitulo(tituloBaixar) }} className={`${validaTema ? 'bg-blue-800' : 'bg-blue-500'}`}>Baixar Tudo</Button>

                </div> */}
            </div>
            <ModalConfimacao show={showModal} setShow={setShowModal} textoModal={textoModal} titulo={textoTitulo} />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema
    }
}

export default connect(mapStateToProps, null)(MCFooter)