import { MD5 } from 'crypto-js';
import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormModal from './FormModal';


function ModalConfirmacao(props) {

    const backgroudClass = (
        props.tema === 'escuro' ?
            'bg-dark text-white border-neutral-700' :
            'bg-light'
    )


    return (
            <Modal show={props.show} className='modal-confirmacao'>
                <Modal.Header className={backgroudClass}>

                </Modal.Header>
                <Modal.Body className={`${backgroudClass} text-center`}>
                    <p className='mb-4 text-lg'>{props.texto}</p>
                    <Spinner />
                </Modal.Body>
                <Modal.Footer className={`${backgroudClass} p-3`}>

                </Modal.Footer>
            </Modal>
    );
}


const mapStateToProps = (state) => {
    return {
        tema: state.appReducer.tema,
        show: state.appReducer.showModalCarregnado,
        texto: state.appReducer.ModalCarregandoTexto
    }
}

export default connect(mapStateToProps, null)(ModalConfirmacao)