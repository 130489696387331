import React from "react";
import PropTypes from "prop-types";

/**
 * Component that renders a message in a table row
 */
function MessageTableRow({ colSpan = 9, message }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <p className="m-2">{message}</p>
      </td>
    </tr>
  );
}

MessageTableRow.propTypes = {
  colSpan: PropTypes.number,
  message: PropTypes.string.isRequired
};

export default MessageTableRow;